var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('NoNavLayout',{attrs:{"noside":""}},[_c('div',{staticClass:"d-flex justify-content-center align-items-center px-3 py-5 h-100"},[_c('Wrapper',{attrs:{"center":"","noinfo":"","contain":"","normal":""}},[_c('div',{staticClass:"d-flex flex-column align-items-center mb-3 px-2"},[(_vm.finishedRegistration && _vm.clientData.isTrial)?_c('TrialPopup'):_vm._e(),(_vm.finishedRegistration)?_c('v-container',[_c('v-row',[_c('v-col',[(_vm.clientData.isTrial)?_c('h2',[_vm._v(" "+_vm._s(_vm.$t("global.yourTrialIsReady"))+" ")]):_c('h2',[_vm._v(_vm._s(_vm.$t("global.youRegisteredSuccessfully")))])])],1),_c('v-row',[_c('v-col',[(_vm.clientData.isTrial)?_c('p',[_vm._v(" "+_vm._s(_vm.$t("global.checkEmailForTrial"))+" ")]):_vm._e(),_c('p',[_vm._v(_vm._s(_vm.$t("global.willGetEmailWithPass")))])])],1),_c('v-col',[_c('a',{attrs:{"href":_vm.newPlayerLink,"target":"_blank"}},[_c('button',{staticClass:"btn btn-danger px-3",staticStyle:{"color":"#fff"},attrs:{"type":"submit"}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.startWatching")))])])])])],1):(
            (_vm.clientData.phone && _vm.clientData.isTrial) || _vm.chooseToGoWithTrial
          )?_c('v-container',[_c('div',{staticClass:"verify-container"},[_c('h1',{staticClass:"verify-header"},[_vm._v(" "+_vm._s(_vm.$t("verification.headerVerification"))+" ")]),(_vm.mode === 'whatsapp')?_c('div',{staticClass:"verify-subtext"},[_c('p',{staticClass:"verify-subtext-item"},[_vm._v(" "+_vm._s(_vm.$t("verification.subtext1Verification"))+" "),_c('WhatsApp')],1),_c('p',{staticClass:"verify-subtext-item2"},[_vm._v(" "+_vm._s(_vm.$t("verification.subtext2Verification"))+" ")])]):_vm._e(),_c('div',{staticClass:"verify-input-el"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.code),expression:"code"}],staticClass:"verify-input",attrs:{"type":"number","placeholder":_vm.$t('verification.placeholderVerification')},domProps:{"value":(_vm.code)},on:{"input":function($event){if($event.target.composing){ return; }_vm.code=$event.target.value}}}),_c('button',{staticClass:"verify-btn",class:!_vm.code ||
                  _vm.code.length < 6 ||
                  _vm.code.length > 6 ||
                  _vm.verifyCounter > 3
                    ? 'disabled-btn'
                    : '',attrs:{"type":"submit"},on:{"click":_vm.verify}},[_vm._v(" "+_vm._s(_vm.$t("verification.btnTextVerification"))+" ")])]),(_vm.verificationError)?_c('p',[_vm._v(_vm._s(_vm.verificationError))]):_vm._e(),(_vm.verifyCounter > 3)?_c('p',[_vm._v(_vm._s(_vm.$t("global.triedToMany")))]):_vm._e(),_c('p',{staticClass:"verify-question"},[_vm._v(" "+_vm._s(_vm.$t("verification.questionVerification"))+" ")]),_c('button',{staticClass:"verify-sms-btn",on:{"click":_vm.switchToSMS}},[_vm._v(" "+_vm._s(_vm.$t("verification.getSms"))+" ")])]),_c('v-row',[_c('v-col',[(_vm.mode === 'sms')?_c('button',{class:_vm.timeUntilNextResend > 0
                    ? 'btn btn-sm btn-outline-danger px-3'
                    : 'btn btn-sm btn-danger px-3',attrs:{"disabled":_vm.timeUntilNextResend > 0,"type":"submit"},on:{"click":_vm.resendCode}},[(_vm.timeUntilNextResend > 0)?_c('span',[_vm._v(_vm._s(_vm.$t("global.resendIn"))+" "+_vm._s(_vm.timeUntilNextResend))]):_c('span',[_vm._v(_vm._s(_vm.$t("global.resend")))])]):_vm._e()])],1)],1):_vm._e(),(_vm.showAreYouSureNoTrial)?_c('v-container',{staticClass:"d-flex flex-column align-items-center mb-3 px-2"},[_c('h2',[_vm._v(_vm._s(_vm.$t("global.oneStepForFreeTrial")))]),_c('v-row',[_c('v-col',[_c('button',{staticClass:"btn btn-sm btn-danger px-3",attrs:{"type":"submit"},on:{"click":function($event){return _vm.resendCode(true)}}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.getFreeTrial")))])]),_c('button',{staticClass:"btn btn-sm btn-outline-danger px-3",attrs:{"type":"submit"},on:{"click":_vm.verify}},[_c('span',[_vm._v(_vm._s(_vm.$t("global.passFreeTrial")))])])])],1)],1):_vm._e(),_c('CloudFlareCaptcha',{ref:"CloudFlareCaptcha"})],1),_c('CloudFlareCaptcha',{ref:"CloudFlareCaptcha"})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }