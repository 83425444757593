<template>
    <li class="startupshow-links-container">
        <h1 class="text">{{ $t(text) }}</h1>
        <div class="d-flex align-center">
            <a :href="link" target="_blank">
                <img src="./assets/startup-show.png" alt="Startup Show" :width="size">
            </a>
            <p class="text mx-3">{{ $t('faq.startupShow.step1.or') }}</p>
            <div class="qr-code--container">
                <vue-qr :text="link" :size="size" :margin="0" />
            </div>
        </div>
    </li>
</template>

<script>
import VueQr from 'vue-qr';

export default {
    components: {
        VueQr
    },
    props: {
        link: String,
        text: String,
        size: Number
    },
};
</script>
<style scoped>
.qr-code--container {
    padding: 8px;
    width: fit-content;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
}

.startupshow-links-container {
    display: flex;
    align-items: center;
    justify-content: space-between; 
    width: 48%;
    padding: 12px;
    border-radius: 8px;

    background-color: rgba(17,16,16,.6);
}
@media only screen and (max-width: 1090px){
    .startupshow-links-container {
        width: 100%;
    }
}
</style>