<template>
  <div class="container">
    <div class="qr-code--container">
      <vue-qr :text="link" :size="size" :margin="0"/>
    </div>
    <p class="qr-code--label">{{ $t("generalTabs.additionalInfoQrCode") }}</p>
  </div>
</template>

<script>
import VueQr from 'vue-qr';

export default {
  data() {
    return {
      size: 80,
    };
  },
  props:{
    link: String,
  },
  components: {
    VueQr,
  }
}
</script>

<style scoped>
p {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.container{
  width: fit-content;
  height: fit-content;
  margin-top: 16px;
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.qr-code--container {
    padding: 20px;
    margin-bottom: 12px;
    width: fit-content;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 8px;
}

.qr-code--label {
    width: 170px;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
}

@media only screen and (max-width: 720px) {
    .container{
      margin-left: 0;
    }
}
</style>
