const getCid = () => {
  const arrCookies = ("; " + document.cookie).split("; trakdesk_cid=");
  const trackingData = arrCookies
    .pop()
    .split(";")
    .shift();
  try {
    const cId = JSON.parse(trackingData).cid;
    return cId;
  } catch {
    //
  }
};
const setTrackDeskCdn = async () => {
  return new Promise((resolve, reject) => {
    try {
      const endpoint = "https://cdn.trackdesk.com/tracking.js";

      const script = document.createElement("script");
      script.src = endpoint;

      script.onload = () => {
        (function(t, d, k) {
          (t[k] = t[k] || []).push(d);
          t[d] =
            t[d] ||
            t[k].f ||
            function() {
              (t[d].q = t[d].q || []).push(arguments);
            };
        })(window, "trackdesk", "TrackdeskObject");
        window.trackdesk("ipartners", "click");
        resolve(getCid());
      };

      script.onerror = () => {
        console.error("Failed to add affiliate script");
        reject("Failed to add affiliate script");
      };

      document.head.appendChild(script);
    } catch (err) {
      console.error("Failed to add affiliate script");
      reject(err);
    }
  });
};

export default { setTrackDeskCdn };
