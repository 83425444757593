<template>
	<PremiumClubLayout v-if="brandName === 'PremiumClub'">
		<PremiumClubChangePassword 
			:submit="submit" 
			:msg="msg" 
			:rules="rules" 
			:password="password"
			:passwordVerification="passwordVerification"
			:updatePasswordVerification="(value) => passwordVerification = value"
			:updatePassword="(value) => password = value" />
	</PremiumClubLayout>
	<DefaultLayout v-else>
		<Default 
			:submit="submit" 
			:rules="rules" 
			:password="password" 
			:passwordVerification="passwordVerification"
			:updatePasswordVerification="(value) => passwordVerification = value"
			:updatePassword="(value) => password = value" />
	</DefaultLayout>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ApiService from '@/services/ApiService.js';
import _ from 'lodash';

export default {
	components: {
		Default: () => import('./components/Default/index.vue'),
		DefaultLayout: () => import('@/layouts/DefaultLayout.vue'),
		PremiumClubLayout: () => import('@/layouts/PremiumClubLayout.vue'),
		PremiumClubChangePassword: () => import('./components/PremiumClub/ChangePassword.vue')
	},
	data() {
		return {
			loading: false,
			password: '',
			passwordVerification: '',
			msg: {
				text: '',
				type: ''
			},
			rules: {
				resetVerification: () => {
					this.passwordVerification = ''
					return true
				},
				max: (value) => (value && value.length <= 20) || this.$t('global.max20Chars'),
				min: (value) => (value && value.length >= 6) || this.$t('global.min6Chars'),
				// eslint-disable-next-line no-irregular-whitespace
				space: (value) => !_.includes(value, /[\u00A0\u1680​\u180e\u2000-\u2009\u200a​\u200b​\u202f\u205f​\u3000 ]/g) || this.$t('global.noSpaces'),
				special: (value) => !value?.match(/[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/) || this.$t('global.specialCarsNotAllowed'),
				required: (value) => !!value || this.$t('global.required'),
				matchingPassword: (value) => value === this.password || this.$t('global.passNoMatch')
			},
		};
	},
	computed: {
		...mapGetters(['token', 'brandConfig', 'brandName'])
	},
	methods: {
		...mapActions(['setClient']),

		async submit(password) {
			const loader = this.$loading.show();
			try {
				await ApiService.clientsApi.changePass(this.token, password);
				const clientInfo = await ApiService.clientsApi.searchClient(this.token);
				this.setClient(clientInfo);
			} catch (error) {
				loader.hide();
				console.log('password change error ====>', error);
				this.msg.text = this.$t('generalTabs.passwordChangeFailed');
				this.msg.type = 'error-msg';
			} finally {
				loader.hide();
				this.msg.text = this.$t('generalTabs.passwordChanged');
				this.msg.type = 'success-msg';
			}
		}
	},
	watch: {
		'msg.type'() {
			setTimeout(() => {
				this.msg = {
					text: '',
					type: ''
				}
			}, 4000);
		}
	}
};
</script>
