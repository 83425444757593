import _ from 'lodash';
import VueRouter from 'vue-router';

const buildRoutes = () => {
	const routes = [];
	const viewsContext = require.context('@/views', true, /index.vue$/);
	_.each(viewsContext.keys(), (key) => {
		let path = key.replace(/(\.|\/index\.vue)/g, '');
		const name = key.replace(/(\.|\/index\.vue|\/)/g, '');
		if (path === '/Profile') {
			path = '/';
		}

		routes.push({ path, name, component: viewsContext(key).default });

		if (path === '/Register') {
			routes.push({ path: '/Register/:trial', name, component: viewsContext(key).default });
		}
	});
	routes.push({ path: '*', redirect: '/' });
	return routes;
};

const createRouter = (brandName) => {
	const router = new VueRouter({ mode: 'history', base: '/', routes: buildRoutes() });
  router.beforeEach((toRoute, _, next) => {
    window.document.title = `${toRoute.name} - ${brandName}`;
    next();
  });
	return router;
};

export { createRouter };