<template>
    <div class="envelope">
        <div class="envelope-container" :class="hideEnvelope ? 'hide-element' : ''">
            <h1 class="envelope-header">{{ $t('generalTabs.inviteYourFriends') }}</h1>
            <p class="envelope-subtext">{{ $t('generalTabs.inclusivePremiumService') }}</p>
            <div class="copy-referral-code" @click="copyToClipboard()">
                <img class="btn-copy-icon" src="@/components/assets/copy.svg" alt="">
                <span class="copy-code-text">{{ $t('generalTabs.copyLink') }}</span>
            </div>
            <span class="envelope-available">{{ $t('generalTabs.oneTimeLink') }}</span>
            <Copied :isCopy="isCopy" />
            <img class="envelope-img" src="@/components/assets/envelope.png" alt="">
        </div>
        <Error class="invite-error-container" v-if="errorText" :text="errorText" />

        <div class="sub-expiry">
            <img src="@/components/assets/sub-icon.svg" alt="">
            <div class="sub-expiry-text">
                <p class="sub-expiry-text-header">{{ $t('generalTabs.subExpiry') }}</p>
                <p class="sub-expiry-text-date">{{ lastSubscriptionEnd }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '@/services/ApiService';

export default {
    props: {
        hideEnvelope: Boolean
    },
    components: {
        Error: () => import('@/views/Login/components/PremiumClub/components/Error.vue'),
        Copied: () => import('@/views/Profile/components/PremiumClub/CopiedSuccessfuly.vue')
    },
    computed: {
        ...mapGetters(['clientObj', 'lastSubscriptionEnd', 'token'])
    },
    data() {
        return {
            isCopy: false,
            errorText: ""
        }
    },
    methods: {
        async copyToClipboard() {
            const res = await ApiService.publicApi.createInvitationLink(this.token);
            if (res.link) {
                navigator.clipboard.writeText(res.link);
                this.isCopy = true;
                setTimeout(() => {
                    this.isCopy = false;
                }, 2000);
            } else {
                if (res.message) {
                    this.errorText = res.message;
                    setTimeout(() => {
                        this.errorText = '';
                    }, 4000)
                }
            }
        }
    }
};
</script>

<style scoped>
h1,
p,
button,
input,
label,
a,
span {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.envelope-header {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
}

.envelope-subtext {
    font-size: 16px;
    line-height: 24px;
    font-weight: 600;
}

.envelope-container {
    background-color: #4E4BCD;
    position: relative;
    border-radius: 16px;
    padding: 64px 12px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
}

.envelope {
    width: 25%;
    margin-top: 120px;
}

.copy-code-text {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    font-size: 13px;
    font-weight: 600;
    line-height: 19.5px;
    margin-left: 8px;
}

.copy-referral-code {
    position: relative;
    background-color: #1F2127;
    border-radius: 8px;
    padding: 12px 0;
    width: 100%;
    margin-top: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.envelope-img {
    position: absolute;
    bottom: 55%;
}

.sub-expiry {
    background-color: rgba(31, 33, 39, .6);
    backdrop-filter: blur(10px);
    border-radius: 16px;
    padding: 16px 0;
    margin-top: 16px;
    display: flex;
    justify-content: center;
}

.sub-expiry-text {
    margin-left: 12px;
}

.sub-expiry-text-header {
    font-size: 12px;
    font-weight: 400;
    line-height: 14.4px;
    color: rgba(255, 255, 255, 0.6);
}

.sub-expiry-text-date {
    font-size: 15px;
    font-weight: 600;
    line-height: 22.5px;
    color: #ffffff;
}

.hide-element {
    display: none;
}

.envelope-available {
    font-size: 12px;
    color: #FFFFFF99;
    line-height: 18px;
    margin-top: 8px;
    font-weight: 600;
}

.invite-error-container {
    margin-top: 10px;
}

@media only screen and (max-width: 1260px) {
    .envelope-header {
        font-size: 16px;
    }

    .envelope-subtext {
        font-size: 13px;
    }
}

@media only screen and (max-width: 1090px) {
    .envelope {
        width: 50%;
    }

    .main-profile {
        flex-direction: column;
        align-items: center;
    }
}

@media only screen and (max-width: 720px) {
    .envelope {
        width: 100%;
        margin-top: 138px;
    }
}
</style>
