<template>
    <Wrapper :heading="$t('global.subscriptions')">
        <h3 class="section-title mb-3">{{ $t('global.newSubcription') }}</h3>
        <div class="row mt-1">
            <div class="col-md-3 mb-3" v-for="pack in packages" :key="pack.name">
                <PlanCard :price="pack.price" :popular="pack.days === 180" :days="pack.days" :id="pack.value"
                    :on-click="getPaymentPage" />
            </div>
        </div>
        <div>
            <Referral />
        </div>
        <h3 class="section-title mb-3 mt-4">{{ $t('global.subcriptionsHistory') }}</h3>
        <div class="mb-4">
            <RefreshPayment @click="handleRefreshTransactions" />
        </div>
        <div class="row">
            <div class="col-md-8">
                <Table :headers="headers" :data="translatedSubscriptionsObj" />
            </div>
        </div>

        <div class="row mt-4" v-if="clientObj.mSite && _.size(translatedSubscriptionsObj) > 0">
            <h3 class="section-title mb-3 mt-4">Steps to gain access to your VPN:</h3>

            <p>1. Download OpenVPN client from: <a style="color: #1976d2;text-decoration: underline;"
                    @click="download('client')">Here</a></p>
            <p>2. Download your file <a style="color: #1976d2;text-decoration: underline;" @click="download()">Here</a>
            </p>
            <p>3. Load your file into the OpenVPN client.</p>
        </div>
    </Wrapper>
</template>

<script>
export default {
    props: {
        packages: Array,
        getPaymentPage: Function,
        handleRefreshTransactions: Function,
        headers: Array,
        translatedSubscriptionsObj: Array,
        download: Function,
        clientObj: Object
    },
    components: {
        Wrapper: () => import('@/components/Wrapper.vue'),
        Table: () => import('@/components/Table.vue'),
        PlanCard: () => import('@/components/PlanCard.vue'),
        Referral: () => import('@/components/Referral.vue'),
        RefreshPayment: () => import('@/components/RefreshPayment.vue'),
    }
};
</script>
<style scoped>
.default-class.apollogroup h3,
.default-class.pizzatime h3 {
    color: white;
}

.section-title {
    font-size: 24px;
    font-weight: 600;
}
</style>
