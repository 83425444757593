<template>
	<DefaultLayout>
		<Wrapper :heading="$t('global.yourSubIsProcessing')" center noinfo>
			<div class="d-flex flex-column justify-content-center align-items-center h-100">
				<div class="d-flex flex-column align-items-center mb-3">
					<p class="order-duration">
						<span>{{ $t('global.willGetEmailWithAllDetails') }}</span>
					</p>
				</div>
			</div>
		</Wrapper>
	</DefaultLayout>
</template>

<script>
export default {
	components: {
		Wrapper: () => import('../../components/Wrapper.vue'),
		DefaultLayout: () => import('../../layouts/DefaultLayout.vue')
	},
	data() {
		return {};
	}
};
</script>

<style scoped>
.order-duration {
	font-size: 18px;
	font-weight: 600;
}
.order-duration span:last-child {
	color: #e52744;
	font-size: 24px;
	padding: 0 0.5rem;
}
</style>
