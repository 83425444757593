<template>
    <GuidesLayout :header="$t('faq.vlc.before')" :subtext="$t('faq.vlc.subtext')">
        <ul class="mb-4">
            <li v-for="item in list1" :key="item" class="standart-text">
                {{ item }}
            </li>
        </ul>
        <h1 class="header-text mb-5">{{ $t('faq.vlc.dontHaveVLC') }}</h1>
        <ul class="list-number mb-4">
            <li class="standart-text">{{ $t('faq.vlc.choose') }}</li>
            <li class="standart-text">{{ $t('faq.vlc.download') }}
                <a class="link" :href="videoLanLink" target="_blank">{{ videoLanLink }}</a>
            </li>
        </ul>
        <h1 class="header-text mb-5">{{ $t('faq.vlc.already') }}</h1>
        <ul class="list-number mb-4">
            <li v-for="item in list2" :key="item" class="standart-text">
                {{ item }}
            </li>
            <p class="standart-text">
                {{ $t('faq.vlc.example') }}
                <span class="link">{{ linkLiveTv }}</span>
            </p>
            <p class="standart-text">
                {{ $t('faq.vlc.replace') }}
                <span class="link">USERNAME/PASSWORD</span>
                {{ $t('faq.vlc.loginInfo') }}
            </p>
            <li v-for="item in list3" :key="item" class="standart-text">
                {{ item }}
            </li>
        </ul>
        <div class="mb-4 img-container">
            <img class="image" src="./assets/image1.png" alt="image" width="460">
        </div>
        <NoteComponent>
            <p class="standart-text">{{ $t('faq.vlc.note') }}</p>
        </NoteComponent>
        <h1 class="header-text my-3">{{ $t('faq.vlc.liveTv') }}</h1>
        <a class="link" :href="linkLiveTv">{{ linkLiveTv }}</a>
        <h1 class="header-text my-3">{{ $t('faq.vlc.movies') }}</h1>
        <a class="link" :href="moviesLink">{{ moviesLink }}</a>
        <h1 class="header-text my-3">{{ $t('faq.vlc.shows') }}</h1>
        <div class="links-container">
            <p class="standart-text">{{ $t('faq.vlc.mostNew') }}</p>
            <a class="link" v-for="(item, index) in 10" :key="index" :href="tvShows">
                {{ tvShows + (index + 1) }}
            </a>
            <p class="standart-text">{{ $t('faq.vlc.oldest') }}</p>
        </div>
    </GuidesLayout>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    components: {
        GuidesLayout: () => import('@/layouts/GuidesLayout.vue'),
        NoteComponent: () => import('@/components/NoteComponent.vue')
    },
    computed: {
        ...mapGetters(['brandConfig']),
        tvlink() {
            return this.brandConfig.tvLink
        },
        linkLiveTv() {
            return `${this.tvlink}/api/list/USERNAME/PASSWORD/m3u8/livetv`
        },
        moviesLink() {
            return `${this.tvlink}/api/list/USERNAME/PASSWORD/m3u8/movies`
        },
        tvShows() {
            return `${this.tvlink}/api/list/USERNAME/PASSWORD/m3u8/tvshows`
        },
    },
    data() {
        const list1 = this.$t("faq.vlc.list1").split(';')
        const list2 = this.$t("faq.vlc.list2").split(';')
        const list3 = this.$t("faq.vlc.list2").split(';')

        const videoLanLink = "https://www.videolan.org/vlc/"

        return {
            list1,
            list2,
            list3,
            videoLanLink
        }
    }
};
</script>

<style scoped>
p,
h1,
li {
    font-size: 13px;
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.standart-text {
    font-size: 16px;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.6);
}

.header-text {
    font-size: 18px;
    font-weight: 600;
}

.link {
    color: #918FFA;
    word-break: break-word;
}

.list-number {
    list-style-type: decimal;
}

.img-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: #11101099;
    border-radius: 8px;
}

.links-container {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 550px) {
    .image {
        width: 100%;
    }
}
</style>
