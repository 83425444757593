<template>
	<PremiumClubLayout v-if="brandName === 'PremiumClub'">
		<PremiumClubProfile :onToggleDontCallMe="onToggleDontCallMe" :createdFunc="createdFunc"/>
	</PremiumClubLayout>
	<DefaultLayout v-else>
		<Default 
			:onToggleDontCallMe="onToggleDontCallMe" 
			:createdFunc="createdFunc" 
			:isBirthdayChangeVisible="isBirthdayChangeVisible" 
			:toggleChangeBirthday="toggleChangeBirthday" 
		/>
	</DefaultLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ApiService from '@/services/ApiService';

export default {
	components: {
		DefaultLayout: () => import('@/layouts/DefaultLayout.vue'),
		Default: () => import('./components/Default/index.vue'),

		PremiumClubLayout: () => import('@/layouts/PremiumClubLayout.vue'),
		PremiumClubProfile: () => import('./components/PremiumClub/ProfileSettings.vue'),
	},
	data() {
		return {
			isBirthdayChangeVisible: false
		}
	},
	computed: {
		...mapGetters(['token', 'brandName', 'setClient'])
	},
	methods: {
		...mapActions(['setDontCallMe']),
		onToggleDontCallMe(value) {
			this.setDontCallMe(value);
		},
		async createdFunc() {
			const activeTransaction = localStorage.getItem('activeTransaction');
			if (activeTransaction !== undefined && activeTransaction !== null) {
				localStorage.removeItem('activeTransaction');
				const { paymentUrl } = await ApiService.transactionsApi.getPaymentLinkByTransactionId(activeTransaction, this.token);
				window.location.href = paymentUrl;
			}
		},
		toggleChangeBirthday() {
			this.isBirthdayChangeVisible = !this.isBirthdayChangeVisible;
		}
	},
};
</script>

<style scoped>
</style>