<template>
    <div class="guides-header">
        <h1 class="guides-title">{{ title }}</h1>
        <p class="guides-subtitle">{{ subtext }}</p>
    </div>
</template>

<script>

export default {
    props: {
        title: String,
        subtext: String
    }
};
</script>

<style scoped>
h1,
p {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.guides-header {
    padding: 70px 117px;
    background: url(./assets/bg-guides-header.png);
    background-size: 100% 200%;
    background-position: 50%;
    border-radius: 16px;
    width: 100%;
}

.guides-title {
    font-size: 48px;
    font-weight: 600;
    line-height: 60.48px;
    margin-bottom: 8px;
    word-wrap: break-word;
}

.guides-subtitle {
    font-size: 18px;
    font-weight: 500;
    max-width: 480px;
    line-height: 22.68px;
}

@media only screen and (max-width: 1024px) {
    .guides-header {
        padding: 58px 16px;
    }

    .guides-title {
        margin-bottom: 4px;
    }
}
</style>
