<template>
	<div class="recaptcha">
		<div class="error-message" v-if="errorMessage">{{ errorMessage }}</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			recaptchaRendered: false,
			errorMessage: null
		};
	},
	created() {
		if (!window.grecaptcha) {
			this.errorMessage = this.$t('global.recaptchaErrorMessage');
		}
	}
};
</script>

<style scoped>
.recaptcha {
	max-width: 650px;
}

.error-message {
	color: #ff5252;
}
</style>
