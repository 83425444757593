<template>
	<div :class="'plan-card p-3 ' + brandName">
		<div v-if="popular" class="popular">{{ $t('global.mostPopular') }}</div>
		<div>
			<div class="plan-card__pricing">
				<span>${{ price }}</span>
				<span>&nbsp;</span>
			</div>
			<p class="plan-card__days py-2 mb-0 mb-md-3">{{ days }} {{ $t('global.days') }}</p>
		</div>

		<div class="plan-card__button">
			<button @click="onClick(id)" :class="popular ? 'btn btn-sm btn-danger px-3' : 'btn btn-sm btn-outline-danger px-3'">
				{{ $t('global.choosePlan') }}
			</button>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	props: {
		popular: Boolean,
		price: Number,
		days: Number,
		id: Number,
		onClick: Function
	},
    computed: {
        ...mapGetters(['brandName'])
    }
};
</script>

<style scoped>
.plan-card {
	border-radius: 8px;
	position: relative;
}
.popular {
	color: white;
}
.PremiumClub span,
.PremiumClub p {
	color: black;
}
.apollogroup span,
.pizzatime span,
.apollogroup p,
.pizzatime p {
	color: white;
}
.plan-card.PremiumClub {
	background-color: #d8d8d8;
}
.plan-card.apollogroup,
.plan-card.pizzatime {
	background-color: #1d1d1d;
}
.plan-card-wide {
	min-width: 215px;
	background-color: #1d1d1d;
	border-radius: 8px;
	position: relative;
}

.plan-card.active {
	background-color: #232323;
}

.plan-card .plan-card__pricing > span:first-child {
	font-size: 30px;
	font-weight: 700;
}

.plan-card .plan-card__pricing > span:last-child {
	font-size: 14px;
	font-weight: 400;
	color: #c4c4c4;
}

.plan-card .plan-card__days {
	font-size: 20px;
	font-weight: 600;
}

.plan-card .plan-card__button {
	display: flex;
	justify-content: center;
}

.plan-card .popular {
	background-color: #2773e5;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 4px 8px;
	border-radius: 43px;
	font-size: 12px;
	position: absolute;
	top: -0.7rem;
	right: 0.5rem;
}

@media only screen and (max-width: 767px) {
	.plan-card {
		display: flex;
		justify-content: space-between;
	}
	.plan-card .plan-card__button {
		align-items: center;
	}
	.plan-card .plan-card__pricing > span:first-child {
		font-size: 24px;
	}
	.plan-card .plan-card__days {
		font-size: 20px;
	}
}
</style>
