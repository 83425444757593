<template>
  <div>
    <dialog ref='dialogRef' class='popup-container'>
      <div v-if='currentMessage' class='dialog-content'>
        <div v-if='currentMessage.disposable' @click='close'>
          <Icon class='icon' icon='ant-design:close-outlined' />
        </div>
        <img v-if='currentMessage.image' :src='currentMessage.image' />
        <div class='subject' v-html='currentMessage.subject'></div>
        <div
          class='content'
          v-html='currentMessage.content'
          @click='clickedContent'
        ></div>
        <button
          class='btn'
          v-if='!currentMessage.showForEver'
          @click='markAsReadAndClose'
        >
          {{ $t('global.close') }}
        </button>
      </div>
    </dialog>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { Icon } from '@iconify/vue2';
import ApiService from '@/services/ApiService';

const CLOSE_POPUP_DATA_ATTR = 'data-close-popup';

const excludedRoutes = ['/WelcomeToPremium'];
const alreadyShown = new Set();

export default {
  name: 'PopupContainer',
  components: { Icon },
  computed: {
    ...mapGetters(['isLoggedIn', 'token']),
    ...mapState(['messages']),
    currentMessage() {
      if (excludedRoutes.includes(this.$route.path)) {
        return null;
      }
      if (!this.messages.length) {
        return null;
      }
      if (alreadyShown.has(this.messages[0].id)) {
        return null;
      }
      alreadyShown.add(this.messages[0].id);
      return this.messages[0];
    },
  },
  created() {
    if (this.isLoggedIn) {
      this.fetchClientMessages();
    }
  },
  watch: {
    isLoggedIn(value) {
      if (value) {
        this.fetchClientMessages();
      }
    },
    currentMessage(value) {
      if (value) {
        this.openModal();
      } else {
        this.closeModal();
      }
    },
  },
  methods: {
    ...mapActions(['fetchClientMessages']),
    close() {
      this.$refs.dialogRef.close();
    },
    async markAsReadAndClose() {
      await ApiService.clientsApi.readMessage(
        this.token,
        this.currentMessage.messageId
      );
      await this.fetchClientMessages();
      this.close();
    },
    clickedContent(e) {
      if (e.srcElement?.hasAttribute(CLOSE_POPUP_DATA_ATTR)) {
        this.close();
      }
    },
    openModal() {
      this.$refs.dialogRef.showModal();
      document.body.style.overflow = 'hidden';
      document.documentElement.style.overflow = 'hidden';
      this.currentMessage.showForEver && document.addEventListener('keydown', this.preventEscClose);
    },
    closeModal() {
      this.$refs.dialogRef.close();
      document.body.style.overflow = '';
      document.documentElement.style.overflow = '';
      this.currentMessage.showForEver && document.removeEventListener('keydown', this.preventEscClose);
    },
    preventEscClose(event) {
      if (event.key === 'Escape') {
        event.preventDefault();
      }
    },
  },
};
</script>

<style scoped>
.popup-container {
}

dialog {
  position: fixed;
  margin: auto;
  min-width: 400px;
  padding: 30px;
  border: none;
  outline: none;
  border-radius: 5px;
  background-color: rgb(26 24 24);
  color: white;
}

dialog::backdrop {
  opacity: 0.8;
  background-color: black;
  backdrop-filter: blur(5px);
}

.dialog-content {
  padding: 10px;
}

.icon {
  font-size: 20px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

dialog >>> h1 {
}

dialog >>> a {
}

dialog >>> a {
}

dialog >>> .btn {
  margin-top: 10px;
  padding-right: 12px;
  padding-left: 12px;
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
</style>
