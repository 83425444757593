<template>
	<NoNavLayout noside>
		<div class="d-flex justify-content-center align-items-center px-3 py-5 h-100">
			<div class="plan-card p-3">
				<div class="u-wrapper" :class="{ center: center, shrink: contain, normal: normal }">
					<h1 class="message">{{ $t('global.unsubscribed') }}</h1>
				</div>
			</div>
		</div>
	</NoNavLayout>
</template>

<script>
import ApiService from '../../services/ApiService.js';
export default {
	components: {
		NoNavLayout: () => import('../../layouts/NoNavLayout.vue')
	},
	data() {
		return {};
	},
	async created() {
		const loader = this.$loading.show();
		await ApiService.clientsApi.unsubscribe(this.$route.query.id, this.$route.query.hash, this.$route.query.type || 'sms');
		loader.hide();
	}
};
</script>

<style scoped>
.plan-card {
	min-width: 215px;
	background-color: #1d1d1d;
	border-radius: 8px;
	position: relative;
}
.message {
	font-size: 30px;
	font-weight: 700;
	color: #fff;
}
</style>
