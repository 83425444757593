import Vue from 'vue';
import Vuex from 'vuex';
import Vuetify from 'vuetify';
import VueHead from 'vue-head';
import 'vuetify/dist/vuetify.min.css';
import VueI18n from 'vue-i18n';
import VueRouter from 'vue-router';
import _ from 'lodash';
import MessageSystem, { MessageTranslations } from '@utt/message-system';
import InputFields, { InputFieldsTranslations } from '@utt/input-fields';
import VueLoading from 'vue-loading-overlay';
import VueTelInput from 'vue-tel-input';
import App from './App.vue';
import { createRouter } from './router/index.js';
import { createStore } from './state/client.js';
import Translations from './locales/index.js';
import VueService from './services/VueService.js';
import ApiService from './services/ApiService';
import 'vue-tel-input/dist/vue-tel-input.css';
import 'vue-loading-overlay/dist/vue-loading.css';

import './services/ApiService.js';
//

const createAppTranslations = () => {
  const moduleTranslations = [MessageTranslations, InputFieldsTranslations];
  return _.transform(
    moduleTranslations,
    (result, translations) => {
      _(translations)
        .keys()
        .each((translationKey) => {
          result[translationKey] = _.merge(
            result[translationKey] || {},
            translations[translationKey]
          );
        });
    },
    {}
  );
};

const main = async () => {
  Object.defineProperty(Vue.prototype, '_', { value: _ });

  const loadingOptions = {
    backgroundColor: 'rgba(255, 255, 255, 0)',
    opacity: 0.1,
    color: '#4e4bcd',
  }

  Vue.config.productionTip = false;
  Vue.use(Vuex);
  Vue.use(VueRouter);
  Vue.use(Vuetify);
  Vue.use(VueI18n);
  Vue.use(VueHead);
  Vue.use(VueLoading, loadingOptions, {});
  Vue.use(MessageSystem);
  Vue.use(InputFields);
  Vue.use(VueTelInput);

  const store = createStore();

  const brandConfig = await ApiService.publicApi.getBrandConfig();

  const router = createRouter(brandConfig.brandName);
  const i18n = new VueI18n({
    locale: 'en',
    messages: _.merge(Translations, createAppTranslations()),
  });
  const vue = new Vue({
    router,
    store,
    render: (h) => h(App, { props: { brandConfig } }),
    vuetify: new Vuetify(),
    i18n,
  });
  VueService.loadVueInstance(vue);
  vue.$mount('#app');
};

main();
