<template>
    <div>
        <FAQHeader />
        <div class="faq-guide">
            <h1 class="main-header mb-4">{{ $t("faqTab.troubleshooting.bitBuy.header") }}</h1>
            <p class="main-text mb-2">{{ $t("faqTab.troubleshooting.bitBuy.step") }}</p>
            <h2 class="second-header mb-2">{{ $t("faqTab.troubleshooting.bitBuy.website") }}</h2>
            <ul class="list-style mb-4">
                <li v-for="item in list1" :key="item">
                    <p class="main-text">
                        {{ item }}
                    </p>
                </li>
            </ul>
            <h2 class="second-header mb-2">{{ $t("faqTab.troubleshooting.bitBuy.app") }}</h2>
            <ul class="list-style mb-4">
                <li v-for="item in list2" :key="item">
                    <p class="main-text">
                        {{ item }}
                    </p>
                </li>
            </ul>
            <h2 class="main-text mb-2">
                <span class="link-text ">{{ $t("faqTab.troubleshooting.bitBuy.important") }}</span>
                {{ $t("faqTab.troubleshooting.bitBuy.wallet") }}
            </h2>
            <FAQGuideFooter guideText="faqTab.troubleshooting.bitBuy.guide"/>
        </div>
    </div>

</template>

<script>
export default {
    components: {
        FAQHeader: () => import('@/components/FAQHeader.vue'),
        FAQGuideFooter: () => import('@/components/FAQGuideFooter.vue')
    },
    data() {
        const list1 = this.$t("faqTab.troubleshooting.bitBuy.list1").split(";")
        const list2 = this.$t("faqTab.troubleshooting.bitBuy.list2").split(";")
        const list3 = this.$t("faqTab.troubleshooting.bitBuy.list3").split(";")

        return {
            list1,
            list2,
            list3
        }

    }
};
</script>

<style scoped>
@import "../../components/styles/faq-guides.css";
</style>
