<template>
  <div :class="'refresh-info px-3 py-2 d-inline-block ' + brandName">
    <p class="pe-2 mb-2">
      {{ $t('global.refreshPayment') }}
    </p>
    <button class="btn btn-success px-3" @click="refreshPayments">{{ $t('global.refreshPaymentBtn') }}</button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from "@/services/ApiService";

export default {
  setup() {},
  components: {},
  computed: {
    ...mapGetters(['token', 'brandName'])
  },
  methods: {
    async refreshPayments() {
      await ApiService.clientsApi.syncBcoinTransactions(this.token);
      this.$emit('click');
    }
  }
};
</script>

<style scoped>
.refresh-info {
  min-width: 490px;
  border-radius: 8px;
  position: relative;
  font-size: 14px;
}
.refresh-info.PremiumClub {
	background-color: #d8d8d8;
  color: black;
  min-width: auto;
}
.refresh-info.apollogroup,
.refresh-info.pizzatime {
	background-color: #5d605c;
  color: white;
}
@media only screen and (max-width: 767px) {
	.refresh-info {
    min-width: auto;
  } 
}
</style>
