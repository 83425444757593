<template>
    <div :class="'note-container ' + customClass">
        <div class="note-icon">
            <img src="@/components/assets/FAQ/note.svg" alt="note-icon">
        </div>
        <div class="content-container">
            <h3 class="note-header">{{ $t("faqTab.note") }}:</h3>
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        customClass: String
    }
}
</script>

<style scoped>
h3 {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}
.note-container {
    width: 100%;
    border-radius: 12px;
    padding: 12px 16px;
    background-color: #11101099;

    display: flex;
}
.note-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    border-radius: 8px;
    background-color: #FFCF2514;
    width: 32px;
    height: 32px;

    margin-right: 16px;
}
.note-header {
    font-size: 11px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
    margin-bottom: 4px;
}
.content-container {
    width: fit-content;
    font-size: 14px;
}
</style>
