<template>
    <GuidesLayout :header="$t('faq.tivimate.tivimateApp')" :subtext="$t('faq.tivimate.tivimateApp')">
        <GuidesStep v-for="step in steps" :key="step.id" :stepHeader="$t(`faq.tivimate.titles.${step.title}`)"
            :stepNumber="step.id">
            <component :is="step.content" />
        </GuidesStep>
    </GuidesLayout>
</template>

<script>

export default {
    components: {
        GuidesLayout: () => import('@/layouts/GuidesLayout.vue'),
        GuidesStep: () => import('@/components/GuidesStep.vue')
    },
    data() {
        return {
            steps: [
                {
                    id: 1,
                    title: 'step1',
                    content: () => import('./components/Step1.vue')
                },
                {
                    id: 2,
                    title: 'step2',
                    content: () => import('./components/Step2.vue')
                },
                {
                    id: 3,
                    title: 'step3',
                    content: () => import('./components/Step3.vue')
                },
                {
                    id: 4,
                    title: 'step4',
                    content: () => import('./components/Step4.vue')
                },
                {
                    id: 5,
                    title: 'step5',
                    content: () => import('./components/Step5.vue')
                },
                {
                    id: 6,
                    title: 'step6',
                    content: () => import('./components/Step6.vue')
                },
                {
                    id: 7,
                    title: 'step7',
                    content: () => import('./components/Step7.vue')
                },
                {
                    id: 8,
                    title: 'step8',
                    content: () => import('./components/Step8.vue')
                },
                {
                    id: 9,
                    title: 'step9',
                    content: () => import('./components/Step9.vue')
                },
            ]
        }
    }
};
</script>
