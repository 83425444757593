export const tryInjectPixel = async (pixelId) => {
  return new Promise((resolve) => {
    try {
      if (!pixelId) {
        resolve();
        return;
      } else {
        const src = "https://connect.facebook.net/en_US/fbevents.js";
        const existingScript = document.querySelector(`script[src="${src}"]`);
        if (!existingScript) {
          const script = document.createElement("script");
          script.async = true;
          script.src = src;
          document.body.appendChild(script);
        }
        window.fbq =
          window.fbq ||
          function() {
            (window.fbq.queue = window.fbq.queue || []).push(arguments);
          };
        if (!window._fbq) window._fbq = window.fbq;
        window.fbq("init", pixelId);
        window.fbq("track", "PageView");
        resolve();
      }
    } catch {
      //continue
      console.error("Failed to load pixel");
      resolve();
    }
  });
};
