<template>
	<NoNavLayout noside>
		<div class="d-flex justify-content-center align-items-center px-3 py-5 h-100">
			<div class="border rounded d-flex flex-column justify-content-center align-items-center">
				<h1 class="text-white m-2">Verify your email</h1>
				<div class="text-white text-center m-2">Please verify
					the
					email (Username) you are inquiring about,
					and
					you will be navigated back to the chat.</div>
				<input class="form-control border w-75" v-model="email" type="text" placeholder="E-mail" />
				<button class="btn bg-white m-2" @click="submit">Submit</button>
				<a id="close" style="display:none;" href="javascript:window.close();">Close</a>
			</div>
		</div>
	</NoNavLayout>
</template>

<script>
import ApiService from '../../services/ApiService';

export default {
	components: {
		NoNavLayout: () => import('../../layouts/NoNavLayout.vue')
	},
	data() {
		return { email: null };
	},

	methods: {
		async submit() {
			const params = new URLSearchParams(window.location.search);
			const token = params.get("token");
			const brand = params.get("brand");
			await ApiService.publicApi.retrieveInfo(token, this.email, brand);
			document.getElementById("close").click();
			window.close();
			return
		},
		closeWindow() {
			if (typeof window.close === 'function') {
				window.open('', '_self', '');
				window.close();
				window.close();
			} else {
				if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
					if (window.open('blank', '_self')) {
						window.close();
					} else {
						window.location.href = 'about:blank';
						window.close();
					}
				} else {
					window.open('', '_self', '');
					window.close();
				}
			}
		}
	}
};
</script>
<style scoped></style>
