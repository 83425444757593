<template>
	<div></div>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '@/services/ApiService';


export default {
	data() {
		return {};
	},
	computed: {
		...mapGetters([ 'token'])
	},
	async created() {
		this.$store.dispatch('logout');

		await ApiService.authApi.logout(this.token);
		this.$router.push(`/`);
	}
};
</script>
