<template>
    <div class="burger-menu-guides">
        <div class="guides-menu">
            <div class="burger-menu-header">
                <router-link to="/">
                    <img class="img" src="@/components/assets/logoPC.svg" alt="logo">
                </router-link>
                <button @click="isMenuHide = !isMenuHide" class="burger-btn">
                    <img src="@/components/assets/burger.svg" alt="">
                </button>
            </div>
            <div v-if="isMenuHide" class="burger-menu-active-container">
                <p class="guides">{{ $t('faq.menu.guides') }}</p>
                <div class="guides-element-container" v-for="item in menuItems" :key="item.id">
                    <div class="guides-element">
                        <div class="element-container">
                            <div :class="item.items.length < 2 ? 'one-element' : 'element-icon-container'"
                                :style="'background-color:' + item.bgColor + ';'">
                                <img class="element-icon" :src="item.icon" alt="">
                            </div>
                            <span class="element-header">{{ item.header }}</span>
                        </div>
                        <img src="./assets/arrow-up.svg" alt="">
                    </div>
                    <p @click="navigate(item.url)" class="element-header nav-item"
                        :class="`/${title}` === item.url ? 'active-item' : ''" v-for="(item, index) in item.items"
                        :key="index">
                        {{ item.header }}
                    </p>
                </div>
                <div class="guides-element-container">
                    <div @click="navigate('/NotSupported')" class="guides-element" style="cursor: pointer;">
                        <div class="element-container">
                            <div class="not-supp-icon-container" :style="'background-color: #D7B12A'">
                                <img class="element-icon" src="./assets/not-supported.svg" alt="">
                            </div>
                            <span class="element-header">{{ $t('faq.notSupported.notSupported') }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        appGuides: Array
    },
    data() {
        return {
            isMenuHide: false,
            menuItems: [
                {
                    id: 1,
                    header: this.$t('faq.menu.mediaServer'),
                    bgColor: '#4E4BCD',
                    items: [],
                    icon: require("./assets/Apps.svg")
                },
                {
                    id: 2,
                    header: this.$t('faq.menu.mobileTv'),
                    bgColor: '#0F7EBC',
                    items: [],
                    icon: require('./assets/mobile-tv.svg')
                },
                {
                    id: 3,
                    header: this.$t('faq.menu.flex'),
                    bgColor: '#0EBE35',
                    items: [],
                    icon: require('./assets/alternative-mobile.svg')
                },
                {
                    id: 4,
                    header: this.$t('faq.menu.amazon'),
                    bgColor: '#BA4D0F',
                    items: [],
                    icon: require('./assets/amazone.svg')
                }
            ]
        }
    },
    methods: {
        navigate(to) {
            if (to !== window.location.pathname) {
                window.scrollTo(0, 0);
                this.$router.replace(to);
            }
        },
        apps() {
            this.appGuides.forEach(item => {
                const index = {
                    mediaService: 0,
                    mobile: 1,
                    ipad: 2,
                    amazone: 3
                }[item.type];
                if (index !== undefined) {
                    this.menuItems[index].items.push(item);
                }
            });
        },
        updateMenuState() {
            this.isMenuHide = window.innerWidth <= 768 ? false : true;
        }
    },
    mounted() {
        this.apps();
        this.updateMenuState();
        window.addEventListener('resize', this.updateMenuState);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.updateMenuState);
    },
};
</script>

<style scoped>
h1,
p,
span {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.burger-menu-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.img {
    width: 70px;
}

.guides-menu {
    position: fixed;
    width: 240px;
    height: 100vh;
    background-color: #1F2127CC;
    padding: 32px 16px;
}

.guides-menu-icon {
    width: 70px;
}

.guides {
    font-size: 9px;
    font-size: 400;
    line-height: 11.34px;
    color: #FFFFFF33;
    padding-top: 16px;
}

.guides-element {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.element-icon-container::before {
    position: absolute;
    bottom: -40px;
    content: '';
    display: inline-block;
    width: 1px;
    height: 33px;
    background-color: #3A3B3F;
}

.one-element::before {
    position: absolute;
    bottom: -17px;
    content: '';
    display: inline-block;
    width: 1px;
    height: 12px;
    background-color: #3A3B3F;
}

.element-icon-container,
.one-element,
.not-supp-icon-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    margin-right: 4.5px;
    padding: 6px;
}

.element-container {
    display: flex;
    align-items: center;
}

.element-icon {
    width: 12px;
}

.element-header {
    font-size: 12px;
    font-weight: 500;
    line-height: 15.12px;
}

.nav-item::before {
    position: absolute;
    left: -17px;
    top: 8px;
    content: '';
    display: inline-block;
    width: 11px;
    height: 1px;
    background-color: #3A3B3F;
}

.nav-item {
    cursor: pointer;
    position: relative;
    margin-left: 30px;
    margin-top: 8px;
    color: #FFFFFF66;
}

.guides-element-container {
    padding-bottom: 12px;
    border-bottom: 1px solid #FFFFFF1F;
    margin-top: 8px;
}

.guides-element-container:last-child {
    border-bottom: none;
}

.active-item {
    color: white;
}

.burger-btn {
    display: none;
}

@media only screen and (max-width: 768px) {
    .burger-btn {
        display: block;
    }

    .guides-menu {
        width: 100%;
        background-color: transparent;
        padding: 0;
        height: auto;
    }

    .guides {
        padding: 0;
    }

    .burger-menu-header {
        background-color: #1D1E24;
        padding: 12px 16px;
    }

    .burger-menu-active-container {
        height: 100vh;
        background-color: #1D1E24;
        padding: 26px 16px;
    }

    .burger-menu-guides {
        position: fixed;
        width: 100%;
        top: 0;

        z-index: 100;
    }
}
</style>
