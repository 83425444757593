/* eslint-disable */
import PublicApi from '../api/public.js';
import TransactionsApi from '../api/transactions.js';
import SubscriptionsApi from '../api/subscriptions';
import ClientsApi from '../api/clients';
import AuthApi from '../api/auth.js';

let apiServiceContainer;

class ApiService {
	#apisContext;
	publicApi;
	transactionsApi;
	subscriptionsApi;
	clientsApi;
	authApi;

	constructor() {
		this.#apisContext = require.context('@/api/', true, /\.js$/);
		this.publicApi = PublicApi;
		this.transactionsApi = TransactionsApi;
		this.subscriptionsApi = SubscriptionsApi;
		this.authApi = AuthApi;
		this.clientsApi = ClientsApi;
	}

	callApi = (apiName, apiFunctionName, parameters = null) => {
		const apiPath = _.find(this.#apisContext.keys(), (key) => _.includes(key, apiName));

		if (!apiPath) {
			throw new Error(`API function not found: ${apiName}/${apiFunctionName}`);
		}
		const apiFunction = this.#apisContext(apiPath)[apiFunctionName];
		if (!apiFunction) {
			throw new Error(`API function not found: ${apiName}/${apiFunctionName}`);
		}

		return apiFunction(parameters);
	};
}

/** @returns {ApiService} */
const initApiService = () => {
	if (!apiServiceContainer) {
		apiServiceContainer = new ApiService();
	}
	return apiServiceContainer;
};

export default initApiService();
