<template>
    <div class="buttons-container">
        <button @click="navigate('prev')" class="button-element"
            :class="buttonName('prev') ? '' : 'diabled-side-menu-btn'">
            <div class="button-header">
                <img class="arrow-icon" src="./assets/arrow-side-bar.svg" alt="arrow-side-bar">
                <p class="btn-text padding-left">{{ $t('faq.buttons.previous') }}</p>
            </div>
            <p class="title-to">{{ buttonName('prev').header || $t('faq.buttons.noPrev') }}</p>
        </button>
        <button @click="navigate('next')" class="button-element"
            :class="buttonName('next') ? '' : 'diabled-side-menu-btn'">
            <div class="button-header">
                <p class="btn-text padding-right">{{ $t('faq.buttons.next') }}</p>
                <img class="arrow-icon rotate" src="./assets/arrow-side-bar.svg" alt="arrow-side-bar">
            </div>
            <p class="title-to">{{ buttonName('next').header || $t('faq.buttons.noNext') }}</p>
        </button>
    </div>
</template>

<script>
export default {
    props: {
        appGuides: Array
    },
    data() {
        return {
            path: this.$router.history.current.name
        }
    },
    methods: {
        buttonName(side) {
            const currentGuide = this.appGuides.find(guide => guide.url === `/${this.path}`);
            if (!currentGuide) return false;

            const currentIndex = this.appGuides.indexOf(currentGuide);
            if (currentIndex === -1) return false;

            if (side === 'prev') {
                const prevGuide = this.appGuides[currentIndex - 1];
                return prevGuide ? prevGuide : false;
            } else if (side === 'next') {
                const nextGuide = this.appGuides[currentIndex + 1];
                return nextGuide ? nextGuide : false;
            }

            return false;
        },
        navigate(to) {
            const nextGuide = this.buttonName(to);
            if (nextGuide) {
                window.scrollTo(0, 0);
                this.$router.replace(nextGuide.url);
            }
        }
    }
};
</script>

<style scoped>
button,
p {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.buttons-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 40px;
}

.button-element {
    width: 49%;
    background-color: #1F2127;
    border-radius: 8px;
    padding: 20px 16px 40px;
}

.btn-text {
    font-size: 14px;
    line-height: 17.64px;
    color: #FFFFFF99;
}

.padding-left {
    padding-left: 8px;
}

.padding-right {
    padding-right: 8px;
}

.button-header {
    display: flex;
    align-items: center;

    margin-bottom: 8px;
}

.arrow-icon {
    margin-bottom: 2px;
}

.rotate {
    transform: rotate(180deg);
}

.title-to {
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    line-height: 20.16px;
}

.diabled-side-menu-btn {
    opacity: 50%;
    pointer-events: none;
}
</style>
