<template>
    <GuidesLayout :mainTitle="$t('faq.menu.recommended')">
        <div v-for="item in steps" :key="item.header">
            <h1 class="recommended-header">{{ item.header }}</h1>
            <img :src="item.img" :alt="item.header" :key="item.header" class="img">
        </div>
        <NoteComponent>
            <p>{{ $t('faq.recommendedHardware.noRecommend') }}</p>
            <p>{{ $t('faq.recommendedHardware.forTheBest') }}</p>
        </NoteComponent>
    </GuidesLayout>
</template>

<script>
export default {
    components: {
        GuidesLayout: () => import('@/layouts/GuidesLayout.vue'),
        NoteComponent: () => import("@/components/NoteComponent.vue")
    },
    data() {
        return {
            steps: [
                {
                    header: this.$t("faq.recommendedHardware.fire"),
                    img: require("@/components/assets/hardware/hardware-1.png")
                },
                {
                    header: this.$t("faq.recommendedHardware.mibox"),
                    img: require("@/components/assets/hardware/hardware-2.png")
                },
                {
                    header: this.$t("faq.recommendedHardware.nvidia"),
                    img: require("@/components/assets/hardware/hardware-3.png")
                },
                {
                    header: this.$t("faq.recommendedHardware.chromecast"),
                    img: require("@/components/assets/hardware/hardware-4.png")
                },
                {
                    header: this.$t("faq.recommendedHardware.walmart"),
                    img: require("@/components/assets/hardware/hardware-5.png")
                }
            ]
        }
    }
};
</script>

<style scoped>
.recommended-header {
    font-size: 1rem;
    margin-bottom: 10px;
}

.img {
    margin-bottom: 25px;
}

@media only screen and (max-width: 1100px) {
    .img {
        width: 100%;
    }
}
</style>
