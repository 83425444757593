<template>
    <div class="modal-container">
        <div class="modal-choose">
            <h1 class="modal-title">{{ $t('global.areYouShure') }} <br /> {{ $t('global.toLogout') }}</h1>
            <div class="modal-buttons-container">
                <button class="modal-button" @click="closeModal">{{ $t('global.cancel') }}</button>
                <button class="modal-button log-out-btn" @click="completeLogout">{{ $t('global.logOut') }}</button>
            </div>
            <button class="close-modal" @click="closeModal">
                <img src="@/components/assets/modal-close-btn.svg" alt="">
            </button>
        </div>
    </div>
</template>

<script>
export default {
    props: ['closeModal', 'completeLogout']
};
</script>

<style scoped>
h1,
p,
button {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.modal-container {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: #14141799;
    z-index: 500;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-choose {
    position: relative;
    background-color: #1F2127;
    padding: 32px;
    width: 450px;
    border-radius: 12px;
}

.modal-title {
    font-size: 28px;
    font-weight: 700;
    line-height: 35.28px;
    text-align: center;
    margin-bottom: 24px;
}

.modal-buttons-container {
    display: flex;
    justify-content: space-between;
}

.modal-button {
    font-size: 14px;
    line-height: 17.64px;
    font-weight: 600;
    color: #4e4bcd;
    padding: 14px 0;
    width: 47%;
    background-color: transparent;
    border-radius: 8px;
    border: 1px solid #4e4bcd;
}

.log-out-btn {
    background-color: #4e4bcd;
    color: #fff;
}

.close-modal {
    position: absolute;
    right: 10px;
    top: 5px;
}

@media only screen and (max-width: 768px) {
    .modal-choose {
        width: 70%;
    }

    .modal-title {
        font-size: 18px;
    }
}
</style>
