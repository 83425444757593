<template>
	<div class="u-side-nav" :class="[mobile ? 'mobile ' + brandName : 'list-group ' + brandName]">
		<router-link 
			v-for="link of filteredLinks()"
			:key="link.id" 
			:to="link.href"
			:class="{
				active: `/${activeRouteName}` === link.href,
				'u-side-nav__logout': link.name === 'global.logOut',
				'list-group-item list-group-item-action': !mobile
			}" 
			class="" 
			aria-current="true">
			<Icon :icon="link.icon" />
			<span>{{ $t(link.name) }}</span>
		</router-link>
	</div>
</template>

<script>
import { Icon } from '@iconify/vue2';
import { mapGetters } from 'vuex';

export default {
	props: {
		links: Array,
		mobile: Boolean,
		activeRouteName: String
	},
	setup() { },
	components: {
		Icon
	},
	computed: {
		...mapGetters(['brandName'])
	},
	methods: {
		filteredLinks() {
			return this.links.filter(link => link.isForMobile);
		},
	}
};
</script>

<style scoped>
.u-side-nav {
	border-radius: 10px;
}

.u-side-nav.PremiumClub {
	background-color: white;
}

.u-side-nav.PremiumClub>a {
	color: black;
}

.u-side-nav.apollogroup,
.u-side-nav.pizzatime {
	background-color: #141414;
}

.u-side-nav.apollogroup>a,
.u-side-nav.pizzatime>a {
	color: white;
}

.u-side-nav.PremiumClub>a:hover {
	background-color: #d8d8d8;
	transition: 0.2s all;
}

.u-side-nav.apollogroup>a:hover,
.u-side-nav.pizzatime>a:hover {
	background-color: #1d1d1d;
	transition: 0.2s all;
}

.u-side-nav>a {
	background-color: inherit;
	border: none;
	padding: 1rem;
}

.u-side-nav>a.active {
	color: #e52744 !important;
	background-color: transparent;
}

.u-side-nav>a svg {
	font-size: 1.2rem;
}

.u-side-nav>a span {
	padding-left: 1rem;
	font-size: 1rem;
	font-weight: 600;
}

.u-side-nav .u-side-nav__logout {
	color: #e52744 !important;
	border-top: 2px solid #1d1d1d;
}

.u-side-nav.mobile {
	display: flex;
	justify-content: space-between;
	border-radius: 0;
	padding: 0.5rem 0;
}

.u-side-nav.mobile>a {
	padding: 0.2rem;
	display: flex;
	flex-direction: column;
	align-items: center;
	flex: 1;
}

.u-side-nav.mobile>a svg {
	font-size: 20px;
}

.u-side-nav.mobile>a span {
	font-size: 10px;
	padding: 0;
}

/* Hide mobile menu if the screen is not mobile */
@media only screen and (min-width: 955px) {
	.u-side-nav.mobile {
		display: none;
	}
}
</style>
