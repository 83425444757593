<template>
	<div :class="path === '/Login' || path === '/Register' ? `default-class login-page ${brandName}` : `default-class ${brandName}`">
		<header
			:class="path === '/Login' || path === '/Register' ? `default-class-header login-page ${brandName}` : `default-class-header ${brandName}`">
			<div v-if="!noinfo && lastSubscriptionEnd" :class="brandName === 'PremiumClub' ? 'd-none' : 'd-md-none'">
				<div :class="'subscription-expiry p-2 d-flex ' + brandName">
					<!-- <Icon icon="ant-design:clock-circle-outlined" /> -->
					<div class="ps-2">
						<p class="m-0 strong">{{ $t('global.subscriptionExpiry') }}</p>
						<!-- eslint-disable-next-line  -->
						<p class="m-0">{{ lastSubscriptionEnd }}{{ new Date(lastSubscriptionEnd) < Date.now() ? `
								(${$t('global.expired')})` : '' }}</p>
					</div>
				</div>
			</div>
			<h1>{{ heading }}</h1>
			<div v-if="!noinfo && lastSubscriptionEnd"
				:class="brandName === 'PremiumClub' ? 'd-none' : 'd-none d-md-block'">
				<div :class="'subscription-expiry p-2 d-flex ' + brandName">
					<!-- <Icon icon="ant-design:clock-circle-outlined" /> -->
					<div class="ps-2">
						<p class="m-0 strong">{{ $t('global.subscriptionExpiry') }}</p>
						<!-- eslint-disable-next-line  -->
						<p class="m-0">{{ lastSubscriptionEnd }}{{ new Date(lastSubscriptionEnd) < Date.now() ? `
								(${$t('global.expired')})` : '' }}</p>
					</div>
				</div>
			</div>
		</header>
		<slot />
	</div>
</template>

<script>
// import { Icon } from '@iconify/vue2';
import { mapActions, mapGetters } from 'vuex';
import fromNow from 'fromnow';
import _ from 'lodash';
import ApiService from '../services/ApiService.js';

export default {
	props: {
		heading: String,
		center: Boolean,
		noinfo: Boolean,
		contain: Boolean,
		normal: Boolean
	},
	data() {
		return {
			lastSubscriptionEnd: '',
			path: window.location.pathname
		};
	},
	components: {
		// Icon
	},
	methods: {
		...mapActions(['setClient', 'setSubscriptions', 'setLastSubscriptionEnd', 'setSiteInfo'])
	},
	computed: {
		...mapGetters(['isLoggedIn', 'token', 'subscriptionsObj', 'brandName', 'chatProvider'])
	},
	async created() {
		const queryParams = window.location.search;
		const hash = _.get(window, 'location.hash');

		const siteInfo = await ApiService.publicApi.getSiteInfo();
		this.setSiteInfo(siteInfo);

		const currentPage = this.$router.history.current.name;
		const loggedOutPages = ['Login', 'Register', 'PhoneValidation', 'ForgotPass', 'ProccessingSubscription'];
		if (!this.isLoggedIn && !_.includes(loggedOutPages, currentPage)) {
			this.$router.push(`/Login${queryParams}`);
			return;
		}

		if (this.token) {
			if (currentPage === 'Login') {
				this.$router.push(`/${queryParams}`);
			}

			if (hash === '#logout') {
				this.$router.push(`/Logout`);
			}

			const loader = this.$loading.show();

			try {
				const [clientInfo, subscriptions] = await Promise.all([ApiService.clientsApi.searchClient(this.token), ApiService.subscriptionsApi.searchSubscriptions(this.token)]);

				this.setClient(clientInfo);
				this.$i18n.locale = clientInfo.language;

				this.setSubscriptions(subscriptions);

				const lastSubscription = _.first(_.orderBy(this.subscriptionsObj, 'startDate', 'desc'));

				// eslint-disable-next-line
				this.lastSubscriptionEnd = lastSubscription
					? new Date(lastSubscription.endDate) < Date.now()
						? this.$t('global.noSubscription')
						: fromNow(lastSubscription.endDate, { and: true, suffix: false, max: 2 })
					: this.$t('global.noSubscription');

				this.setLastSubscriptionEnd(this.lastSubscriptionEnd)

				if (hash === '#subscribe') {
					this.$router.push(`/Subscriptions${queryParams}`);
				}

				loader.hide();
			} catch (err) {
				loader.hide();
			}
		} else {
			const queryStringParams = Object.fromEntries(new URLSearchParams(window.location.search));
			this.$i18n.locale = queryStringParams.lang || 'en';
		}
	}
};
</script>

<style scoped>
.default-class-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-bottom: 16px;
}

.default-class-header.login-page {
	justify-content: center;
}

.default-class-header.PremiumClub {
	padding: 0;
}

.default-class {
	width: 100%;
	display: flex;
	flex-direction: column;
	display: inline-block;
}

.default-class.login-page {
	width: auto;
}

.default-class.login-page.apollogroup header h1,
.default-class.login-page.pizzatime header h1 {
	margin-top: 60px;
}

.default-class.apollogroup,
.default-class.pizzatime {
	background-color: var(--wrapper-bg-color);
	background: linear-gradient(162deg, rgba(26, 34, 44, 1) 0%, rgba(0, 4, 9, 1) 100%) !important;
	border-radius: 10px;
	color: #fff;
	padding: 2rem;
}

.default-class.PremiumClub {
	flex-direction: column;
	height: fit-content;
	background-color: rgba(31, 33, 39, .6);
	backdrop-filter: blur(10px);
	padding: 24px 32px;
	border-radius: 0 0 12px 12px;
}

.default-class.apollogroup header h1,
.default-class.pizzatime header h1 {
	color: #fff;
	font-size: 32px;
	font-weight: 700;
	margin-bottom: 10px;
}

.subscription-expiry {
	color: white;
	font-size: 0.8rem;
	background-color: #2773e5;
	border-radius: 5px;
	width: 100%;
	/* max-width: 222px; */
}

.subscription-expiry>svg {
	font-size: 1.2rem;
}

.subscription-expiry .strong {
	font-weight: 600;
}

header {
	justify-content: center;
	text-align: center;
}

.default-class.login {
	width: auto;
}

@media only screen and (max-width: 768px) {
	.default-class.PremiumClub {
		border-radius: 12px;
	}
	.default-class-header {
		flex-direction: row-reverse;
	}

	.default-class.apollogroup,
	.default-class.pizzatime {
		height: 100vh;
		overflow: auto;
		border-radius: 0;
	}

	.subscription-expiry {
		margin-bottom: 1rem;
	}
}
</style>
