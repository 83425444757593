<template>
	<div :class="'subscribe-info p-3 d-inline-block ' + brandName">
		<p class="pe-3" v-if="!clientObj.mSite">
			{{ $t('global.subscribeAndEnjoy100') }}
		</p>
		<p class="pe-3" v-else>
			{{ $t('global.subscribeAndEnjoy') }}
		</p>
		<button class="btn btn-danger px-3" @click="goToSubscriptionPage">{{ $t('global.subscribeNow') }}</button>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	setup() {},
	components: {},
	computed: {
		...mapGetters(['clientObj', 'brandName'])
	},
	methods: {
		goToSubscriptionPage() {
			this.$router.push('/Subscriptions');
		}
	}
};
</script>

<style scoped>
.subscribe-info {
	min-width: 490px;
	border-radius: 8px;
	position: relative;
	font-size: 14px;
}
.subscribe-info.PremiumClub {
	background-color: #d8d8d8;
	color: black;
	min-width: auto;
}
.subscribe-info.apollogroup,
.subscribe-info.pizzatime {
	background-color: #333333;
	color: white;
}

.subscribe-info .subscribe-info__close {
	top: 0;
	right: 0;
}
@media only screen and (max-width: 768px) {
	.subscribe-info {
		min-width: auto;
	}
}
</style>
