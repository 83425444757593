<template>
	<!-- The container where the captcha will render -->
	<div :id="cssSelector.substring(1)" ref="captchaContainer"></div>
</template>

<script>
import VueService from '../services/VueService';
export default {
	props: {
        startAuto: {
			type: Boolean,
			default: true
		}
    },
	name: "CloudFlareCaptcha",
	data() {
		return {
			captchaContainer: null,
			cssSelector: '#cloudflare-recaptcha',
			sitekey: '0x4AAAAAAAzGftvYqpmhGxGl',
			widgetId: '',
			resultToken: ''
		};
	},
	mounted() {
		this.captchaContainer = this.$refs.captchaContainer;
		if (this.captchaContainer && this.startAuto) {
			this.initializeCaptcha();
		}
	},
	methods: {
		initializeCaptcha() {
				if (!window.turnstile) {
					console.error("Turnstile is not defined.");
					return;
				}
				window.turnstile.ready(() => {
					if (this.isAlreadyRendered()) {
						window.turnstile.remove(this.cssSelector);
					}

					const widgetId = window.turnstile.render(this.cssSelector, {
						sitekey: this.sitekey,
						callback: (token) => {
							this.resultToken = token;
						},
					});
					this.widgetId = widgetId;
			});
		},
		isAlreadyRendered() {
			try {
				window.turnstile.getResponse(this.widgetId);
				return true;
			} catch (e) {
				return false;
			}
		},
		getCapatchToken() {
			if (!this.resultToken) {
				VueService.showToastMessage({ message: 'Verify Captcha.', type: 'error' });
				return null;
			}
			return this.resultToken;
		},
		resetCaptcha() {
			this.resultToken = '';
			window.turnstile.reset(this.widgetId);
		}
	},
};
</script>