<template>
	<div>
		<table class="table table-striped d-none d-md-table" :class="brandName === 'PremiumClub' ? `table-white ${brandName}` : `table-dark ${brandName}`">
			<thead class="w-100">
				<tr>
					<th v-for="(header, index) in headers" :key="index">
						{{ header }}
					</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="(row, index) in data" :key="index">
					<td v-for="(value, key, index) in row" :key="index">
						{{ value }}
					</td>
				</tr>
			</tbody>
		</table>
		<div :class="'mobile-table mb-3 d-block d-md-none ' + brandName"   v-for="(row, index) in data" :key="index">
			<div class="row pb-3" v-for="(value, key, i) in row" :key="i">
				<div class="mobile-table__heading col-4">
					{{ headers[i] }}
				</div>
				<div class="col-8">
					{{ value }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	props: {
		headers: Array,
		data: Array
	},
	computed: {
		...mapGetters(['brandName'])
	},
	setup() {}
};
</script>

<style scoped>
tr {
	border-style: none;
}
.table-striped.PremiumClub > tbody > tr:nth-of-type(odd) {
	--bs-table-accent-bg: #d8d8d8;
}
.table-striped.PremiumClub > tbody > tr:nth-of-type(even) {
    --bs-table-accent-bg: #f5f5f5;
}
.mobile-table {
	font-size: 12px;
}
.mobile-table.apollogroup div,
.mobile-table.pizzatime div {
	color: white;
}
.mobile-table.PremiumClub div {
	color: black;
}
.mobile-table .mobile-table__heading {
	font-weight: 600;
}
.mobile-table.apollogroup,
.mobile-table.pizzatime {
	border-bottom: 1px solid rgba(255, 255, 255, 0.12);
}
.mobile-table.PremiumClub {
	border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
</style>
