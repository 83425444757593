<template>
    <div></div>
</template>

<script>
export default {
    mounted() {
        const handleChatInteraction = () => {
            const sendpulseContainer = document.getElementsByTagName('sp-live-chat')[0];
            if (sendpulseContainer && sendpulseContainer.shadowRoot) {
                const sendpulseButton = sendpulseContainer.shadowRoot.querySelector(".widget-fab");
                if (sendpulseButton) {
                    sendpulseButton.click();
                }
            }
        };

        setTimeout(()=>handleChatInteraction() , 100);
    }
};
</script>
