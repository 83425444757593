<template>
	<NoNavLayout noside>
		<div class="d-flex justify-content-center align-items-center px-3 py-5 h-100">
			<Wrapper :heading="$t('global.forgotPassword')" center noinfo contain normal>
				<div class="d-flex flex-column align-items-center mb-3 px-2">
					<v-container v-if="passSent">
						<v-col>
							<p>{{ $t('global.emailWithYourAccountDetailsWasSent') }}</p>
						</v-col>
					</v-container>
					<v-container v-else>
						<v-form v-model="isFormValid">
							<v-row>
								<v-col>
									<input-field
										type="email"
										color="white"
										:rules="[rules.required, rules.email]"
										:props="{ styleType: 'outlined' }"
										v-model="email"
										required
										:label="$t('global.email')"
									/>
								</v-col>
								<v-col>
									<button :class="isFormValid ? 'btn btn-sm btn-danger px-3' : 'btn btn-sm btn-outline-danger px-3'" :disabled="!isFormValid" @click="submit(email)">
										{{ $t('global.sendNewPassword') }}
									</button>
								</v-col>
							</v-row>
						</v-form>
					</v-container>
					<v-row>
						<v-col>
							<button type="submit" class="btn btn-sm btn-danger px-3" @click="goToLoginPage">
								<span>{{ $t('global.backToLogin') }}</span>
							</button>
						</v-col>
					</v-row>
				</div>
			</Wrapper>
		</div>
	</NoNavLayout>
</template>

<script>
export default {
    props: {
        goToLoginPage: Function,
        submit: Function,
		rules: Object,
		passSent: Boolean,
		isFormValidProp: Boolean
    },
	components: {
		Wrapper: () => import('@/components/Wrapper.vue'),
		NoNavLayout: () => import('@/layouts/NoNavLayout.vue')
	},
	data() {
		return {
			email: '',
			isFormValid: this.isFormValidProp
		};
	},
};
</script>
<style scoped>
.default-class {
	color: white !important;
	max-width: 550px;
}
</style>
