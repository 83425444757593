<template>
    <GuidesLayout :mainTitle="$t('faq.startupShow.startupShow')">
        <h1 class="text mb-2">{{ $t('faq.startupShow.step1.download') }}</h1>
        <p class="text-gray mb-2">{{ $t('faq.startupShow.step1.click') }}</p>
        <ul class="p-0 d-flex justify-space-between mb-5 startup-qr-container">
            <StartupShowQr v-for="item in appsList" :key="item.text" :link="item.link" :text="item.text" :size="72" />
        </ul>

        <div v-if="m3uLinkExample" class="additional-info--item-content mb-5">
            <div class="additional-info--item">
                <div class="additional-info--item-container">
                    <h1 class="text mb-2">{{ $t('faq.startupShow.step2.login') }}</h1>
                    <p class="text-gray mb-2">{{ $t('faq.startupShow.step2.useLink') }}</p>
                    <input class="additional-info--item-input link" type="text" :value="m3uLinkExample" readonly>
                </div>
                <div @click="copyToClipboard(m3uLinkExample)" class="additional-info--item-copy">
                    <img class="additional-info--item-copy-img" src="@/components/assets/copy.svg" alt="">
                </div>
            </div>
            <div class="qr-code--mobile-container">
                <QrCode :link="m3uLinkExample" />
            </div>
        </div>
        <GuidesStep v-for="step in steps" :key="step.id" :stepHeader="$t(`faq.startupShow.${step.title}`)"
            :stepNumber="step.id">
            <component :is="step.content" />
        </GuidesStep>
    </GuidesLayout>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    components: {
        GuidesLayout: () => import('@/layouts/GuidesLayout.vue'),
        QrCode: () => import('@/components/QrCode.vue'),
        StartupShowQr: () => import('@/components/StartupShowQr.vue'),
        GuidesStep: () => import('@/components/GuidesStep.vue')
    },
    methods: {
        copyToClipboard(value) {
            navigator.clipboard.writeText(value)
        },
    },
    computed: {
        ...mapGetters(['brandConfig']),
        appsList() {
            return [
                {
                    link: this.brandConfig.apps.android,
                    text: 'faq.startupShow.step1.android'
                },
                {
                    link: this.brandConfig.apps.ios,
                    text: 'faq.startupShow.step1.ios'
                }
            ]
        },
        m3uLinkExample() {
            return `${this.brandConfig.tvLink}/api/list/username/password` 
        }
    },
    data() {
        return {
            steps: [
                {
                    id: 1,
                    title: 'android.follow',
                    content: () => import('./components/Step1.vue')
                },
                {
                    id: 2,
                    title: 'ios.follow',
                    content: () => import('./components/Step2.vue')
                },
            ]
        }
    }
};
</script>
<style scoped>
@import "../../components/styles/guides-default-styles.css";

.text-gray {
    opacity: 65%;
}

.additional-info--item {
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 16px 0;
    width: 100%;
}

.additional-info--item-header {
    font-size: 14px;
    font-weight: 600;
    line-height: 17.64px;
}

.additional-info--item-label {
    font-size: 13px;
    margin-bottom: 12px;
}

.additional-info--item-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
}

.additional-info--item-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.additional-info--item-copy {
    position: absolute;
    padding: 5px 8px;
    border-radius: 4px;
    background-color: rgba(255, 255, 255, 0.12);
    right: 2%;
    bottom: 22px;
    cursor: pointer;
}

.additional-info--item-copy-img {
    width: 16px;
}

.additional-info--item-input {
    width: 100%;
    background-color: rgba(255, 255, 255, 0.1);
    color: rgba(255, 255, 255, 0.5);
    font-size: 15px;
    font-weight: 400;
    border-radius: 8px;
    padding: 12px 35px 12px 16px;
    outline: none;
    border: 1px solid rgba(255, 255, 255, 0.1);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.link {
    color: #918FFA;
}

@media only screen and (max-width: 1090px) {

    .startup-qr-container,
    .additional-info--item-content {
        flex-direction: column;
    }
}
</style>