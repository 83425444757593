/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';
import _ from 'lodash';
import moment from 'moment';
import ApiService from '../services/ApiService.js';
import { tryInjectPixel } from '../utils/tracking.js';

let store;

const createStore = () => {
	if (store) {
		return store;
	}

	let token = null;
	try {
		token = localStorage.getItem('token');
	} catch {
		// e
	}

	store = new Vuex.Store({
		state: {
			token,
			clientObj: {},
			siteInfo: { openedForTrial: true },
			subscriptionsObj: {},
			isDarkMode: false,
			brandName: '',
			messages: [],
			pixelId: '',
			brandConfig: null,
			lastSubscriptionEnd: null,
			chatProvider: null,
			translatedSubscriptionsObj: [],
			widgetId: ''
		},
		mutations: {
			setDarkMode: (state, darkmode) => {
				state.isDarkMode = darkmode;
			},
			setClient: (state, clientObj) => {
				Vue.set(state, 'clientObj', clientObj);
			},
			setSubscriptions: (state, subscriptionsObj) => {
				state.subscriptionsObj = subscriptionsObj;
			},
			resetToken: (state) => {
				localStorage.removeItem('token');
				state.token = null;
			},
			setToken: (state, token) => {
				localStorage.setItem('token', token);
				state.token = token;
			},
			setSiteInfo: (state, siteInfo) => {
				state.siteInfo = siteInfo;
			},
			setBrandName: (state, brandName) => {
				state.brandName = brandName;
			},
			setChatProvider: (state, chatProvider) => {
				state.chatProvider = chatProvider;
			},
			setWidgetId: (state, widgetId) => {
				state.widgetId = widgetId;
			},
			setMessages: (state, messages) => {
				state.messages = messages;
			},
			setPixelId: (state, pixelId) => {
				state.pixelId = pixelId;
			},
			setBrandConfig: (state, brandConfig) => {
				state.brandConfig = brandConfig;
			},
			setLastSubscriptionEnd: (state, lastSubscriptionEnd) => {
				state.lastSubscriptionEnd = lastSubscriptionEnd
			},
			setTranslatedSubscriptionsObj: (state, translatedSubscriptionsObj) => {
				state.translatedSubscriptionsObj = translatedSubscriptionsObj
			}

		},
		actions: {
			setClient: (context, clientObj) => {
				context.commit('setClient', clientObj);
			},
			setBrandConfig: (context, brandConfig) => {
				context.commit('setBrandConfig', brandConfig);
			},
			setDontCallMe: (context, value) => {
				context.commit('setClient', { ...context.getters.clientObj, dontCallMe: !value });
				const token = context.getters.token;
				ApiService.clientsApi.updateCallMe(token, !value);
			},
			setSubscriptions: (context, subscriptionsObj) => {
				context.commit('setSubscriptions', subscriptionsObj);
			},
			setLastSubscriptionEnd: (context, lastSubscriptionEnd) => {
				context.commit('setLastSubscriptionEnd', lastSubscriptionEnd);
			},
			logout: async (context) => {
				context.commit('resetToken');
			},
			setToken: (context, token) => {
				context.commit('setToken', token);
			},
			setSiteInfo: (context, siteInfo) => {
				context.commit('setSiteInfo', siteInfo);
			},
			setWidgetId: (context, widgetId) => {
				context.commit('setWidgetId', widgetId);
			},
			setBrandName: (context, brandName) => {
				context.commit('setBrandName', brandName)
			},
			fetchClientMessages: async (context) => {
				if (!context.state.token) {
					return;
				}
				const messages = await ApiService.clientsApi.getClientMessages(context.state.token);
				context.commit('setMessages', messages);
			},
			getPixelId: async () => {
				const queryStringParams = Object.fromEntries(new URLSearchParams(window.location.search));
				const linkId = queryStringParams?.linkId;
				if (!linkId) {
					return;
				} else {
					const pixelId = await ApiService.publicApi.getPixelId(linkId).catch(err => console.log(err, 'error loading pixel'));
					if (pixelId) {
						await tryInjectPixel(pixelId).catch(err => console.log(err, 'error loading pixel'));
					}					
				}
			},
			setChatProvider: (context, chatProvider) => {
				context.commit('setChatProvider', chatProvider)
			},
			setBirthday: async (context, value) => {
				try {
					const token = context.getters.token;
					await ApiService.clientsApi.changeBirthday(token, value);
					
					context.commit('setClient', { ...context.getters.clientObj, birthDate: new Date(value).toISOString() });
				} catch(e) {
					throw e;
				}
			},
			setTranslatedSubscriptionsObj: (context, translatedSubscriptionsObj) => {
				context.commit('setTranslatedSubscriptionsObj', translatedSubscriptionsObj)
			}
		},
		getters: {
			isLoggedIn: (state) => {
				return state.token;
			},
			token: (state) => {
				return state.token;
			},
			clientObj: (state) => {
				return { ...state.clientObj, dontCallMe: !state.clientObj.dontCallMe };
			},
			siteInfo: (state) => {
				return state.siteInfo;
			},
			widgetId: (state) => {
				return state.widgetId;
			},
			subscriptionsObj: (state) => {
				return state.subscriptionsObj;
			},
			transformedSubscriptionsObj: (state) => {
				return _.map(state.subscriptionsObj, (subscription) => {
					return {
						subscriptionId: subscription.id,
						packagePeriodInDays: subscription.packagePeriodInDays,
						startDate: moment(subscription.startDate).format('yyyy-MM-DD')
					};
				});
			},
			brandName: (state) => {
				return state.brandName
			},
			brandConfig: (state) => {
				return state.brandConfig
			},
			pixelId: (state) => {
				return state.pixelId
			},
			chatProvider: (state) => {
				return state.chatProvider;
			},
			lastSubscriptionEnd: (state) => {
				return state.lastSubscriptionEnd
			},
			translatedSubscriptionsObj: (state) => {
				return state.translatedSubscriptionsObj
			},
			translatedSubscriptionsObj: (state) => {
				return state.translatedSubscriptionsObj
			}
		}
	});

	return store;
};

export { createStore };
