<template>
    <div class="welcome-page">
        <div class="welcome-container">
            <img class="welcome-logo" src="./assets/logo.svg" alt="">
            <h1 class="welcome-title">{{ $t("generalTabs.welcome") }}</h1>
            <p class="welcome-subtitle">{{ $t("generalTabs.joinNewBrand") }}</p>
            <div class="welcome-input-container">
                <p class="welcome-input-text">{{ $t("global.username") }}</p>
                <input v-model="username" class="welcome-input" type="text">
                <p class="welcome-error" v-if="usernameErrorMessage">{{ usernameErrorMessage }}</p>
            </div>
            <button @click="switchBrand" class="welcome-btn"
                :class="!isNotChecked || !isValidUserName || username.length === 0 ? 'welcome-btn-disabled' : ''">{{ $t("generalTabs.joinUs")
                }}</button>
            <div class="welcome-agree">
                <label class="checkbox-container">
                    <input v-model="isNotChecked" class="welcome-checkbox" type="checkbox">
                    <div :class="isNotChecked ? 'custom-checkbox active' : 'custom-checkbox'"></div>
                    <div class="checkmark" :class="isNotChecked ? 'active' : ''"></div>
                </label>
                <p class="welcome-agree-text">{{ $t("generalTabs.iAgree") }}
                    <a href="/PrivacyPolicy" target="_blank" class="privacy-policy">{{ $t("generalTabs.privacyPolicy")
                    }}</a>
                </p>
            </div>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import ApiService from '@/services/ApiService';

export default {
    data() {
        return {
            username: '',
            isNotChecked: false,
            usernameErrorMessage: ''
        }
    },
    computed: {
        ...mapGetters(['isLoggedIn', 'token', 'subscriptionsObj', 'brandName']),
        isValidUserName(){
          const validUsername = /^[0-9A-Za-z_/-]{6,16}$/;
          return this.username.length === 0 || validUsername.test(this.username);
        },
    },
    async created() {
        if (!this.isLoggedIn) {
            return this.$router.push(`/Login`);
        }
        const res = await ApiService.publicApi.isPermittedToPremium(this.token);
        if (!res.isPermittedToPremium) {
            return this.$router.push('/Profile');
        }
    },
    methods: {
        async switchBrand() {
            const res = await ApiService.publicApi.switchToPremiumClubBrand('PremiumClub', this.username, this.token);
            if (!res.success) {
                this.usernameErrorMessage = res.message;
                return
            }
            await this.$store.dispatch('logout');

            const parentWindow = window.parent;
            parentWindow.postMessage({ url: res.url, type: 'redirect' }, '*');
            window.location = res.url;
        },
    },
    watch: {
      isValidUserName(isValid){
        this.usernameErrorMessage = isValid ? '': this.$t('global.fieldMustBe');
      }
    }
}
</script>
<style scoped>
h1,
p,
button,
input,
a {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
    padding: 0;
}

.welcome-page {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100vh;
    background: url('./assets/background.jpg');

    -webkit-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -ms-background-size: 100% 100%;
    -khtml-background-size: 100% 100%;
    background-size: 100% 100%;
}

.welcome-container {
    max-width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    background-color: #1F212799;
    backdrop-filter: blur(10px);
    padding: 60px 32px;
    border-radius: 12px;
}

.welcome-logo {
    width: 104px;
    margin: 0 auto;
    margin-bottom: 24px;
}

.welcome-container h1 {
    font-weight: 700;
    font-size: 28px;
    line-height: 35, 28px;
    margin-bottom: 16px;
}

.welcome-subtitle {
    font-weight: 400;
    font-size: 13px;
    line-height: 19, 5px;
    margin-bottom: 24px;
}

.welcome-input-container p {
    font-weight: 400;
    font-size: 13px;
    line-height: 16, 38px;
    margin-bottom: 8px;
}

.welcome-input {
    width: 100%;
    padding: 12px 16px;
    border: 1px solid #FFFFFF1A;
    border-radius: 8px;
    outline: none;
    background-color: #FFFFFF0A;
    backdrop-filter: blur(10px);


    font-weight: 400;
    font-size: 13px;
    line-height: 16, 38px;
}

.welcome-btn {
    width: 100%;
    border: none;
    outline: none;
    background-color: #4E4BCD;
    font-weight: 600;
    font-size: 14px;
    line-height: 17, 64px;
    border-radius: 8px;
    padding: 12px 0;
    margin-top: 24px;
    margin-bottom: 16px;
}

.welcome-agree {
    display: flex;
    align-items: flex-start;
}

.welcome-agree p {
    color: #FFFFFF99;
    margin-bottom: 0;
    font-size: 13px;
    font-weight: 400;
    line-height: 19, 5px;
    margin-left: 9px;
}

.privacy-policy {
    color: #918FFA;
    text-decoration: underline;
    cursor: pointer;
}

.welcome-checkbox {
    position: absolute;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.checkbox-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
    padding-top: 3px;
}

.custom-checkbox {
    width: 18px;
    height: 18px;
    border: 2px solid #888;
    border-radius: 2px;
    background-color: transparent;
    cursor: pointer;
}

.custom-checkbox.active {
    background-color: #4E4BCD;
    border: 2px solid #4E4BCD;
}

.checkmark {
    position: absolute;
    top: 28%;
    left: 32%;
    transform: rotate(45deg);
    width: 6px;
    height: 10px;
    border-right: 2px solid #1F212799;
    border-bottom: 2px solid #1F212799;
    opacity: 0;
}

.checkmark.active {
    opacity: 1;
}

.welcome-error {
    font-family: 'Wix Madefor Display', sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 19.5px;
    color: #FF254C;
}

.welcome-btn-disabled {
    pointer-events: none;
    opacity: 50%;
}

@media only screen and (max-width: 425px) {
    .welcome-container {
        max-width: auto;
        margin: 10px;
    }

    .welcome-container h1 {
        font-size: 26px;
    }
}

@media only screen and (max-width: 375px) {}</style>
