export function createLinks() {
    const links = [
        {
            id: 1,
            name: 'global.profile',
            href: '/Profile',
            icon: 'bx:bxs-user-circle',
            isForMobile: true
        },
        {
            id: 6,
            name: 'global.apps',
            href: '/Apps',
            icon: 'fluent:apps-16-regular',
            isForMobile: true
        },
        {
            id: 2,
            name: 'global.transactions',
            href: '/Transactions',
            icon: 'bx:bx-credit-card',
            isForMobile: true
        },
        {
            id: 3,
            name: 'global.subscriptions',
            href: '/Subscriptions',
            icon: 'mdi:youtube-subscription',
            isForMobile: true
        },
        {
            id: 4,
            name: 'global.changePassword',
            href: '/ChangePassword',
            icon: 'clarity:lock-line',
            isForMobile: true
        },
        {
            id: 7,
            name: 'global.faq',
            href: '/FAQ',
            icon: 'wpf:faq',
            isForMobile: true
        },
        {
            id: 5,
            name: 'global.logOut',
            href: '/Logout',
            icon: 'mdi:logout',
            isForMobile: true
        }
    ];

    return links
}