/* eslint-disable */
import { apiRequest } from './api.js';

const BASE_URL = '/api/public';

const login = (username, password) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/login`, body: { username, password } });
const logout = (token) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/logout`, body: { token } });
const magicLinkLogin = (token) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/magicLinkLogin`, body: { token } });

export default { login, logout, magicLinkLogin };
