<template>
    <div>
        <FAQHeader />
        <div class="faq-guide">
            <h1 class="main-header mb-4">{{ $t("faqTab.troubleshooting.shakepay.header") }}</h1>
            <p class="main-text mb-4">{{ $t("faqTab.troubleshooting.shakepay.transaction") }}</p>
            <p class="main-text mb-4">{{ $t("faqTab.troubleshooting.shakepay.process") }}</p>
            <h2 class="second-header mb-2">{{ $t("faqTab.troubleshooting.shakepay.steps") }}</h2>
            <ul class="list-style mb-4">
                <li v-for="item in list1" :key="item">
                    <p class="main-text">{{ item }}</p>
                </li>
            </ul>
            <h2 class="second-header mb-2">{{ $t("faqTab.troubleshooting.shakepay.funds") }}</h2>
            <p class="main-text">{{ $t("faqTab.troubleshooting.shakepay.important") }}</p>
            <ul class="list-style mb-4">
                <li v-for="item in list2" :key="item">
                    <p class="main-text">{{ item }}</p>
                </li>
            </ul>
            <h2 class="second-header mb-2">{{ $t("faqTab.troubleshooting.shakepay.buyBitcoin") }}</h2>
            <ul class="list-style mb-4">
                <li v-for="item in list3" :key="item">
                    <p class="main-text">{{ item }}</p>
                </li>
            </ul>
            <h1 class="second-header mb-2">{{ $t("faqTab.troubleshooting.shakepay.send") }}</h1>
            <ul class="list-style mb-4">
                <li v-for="item in list4" :key="item">
                    <p class="main-text">{{ item }}</p>
                </li>
            </ul>            
            <FAQGuideFooter guideText="faqTab.troubleshooting.shakepay.guide"/>
        </div>
    </div>

</template>

<script>
import { mapGetters } from "vuex"

export default {
    components: {
        FAQHeader: () => import('@/components/FAQHeader.vue'),
        FAQGuideFooter: () => import('@/components/FAQGuideFooter.vue')
    },
    computed: {
        ...mapGetters(['brandConfig']),
        loginLink() {
            return `${this.brandConfig.portalLink}/Login`
        }
    },
    data() {
        const list1 = this.$t("faqTab.troubleshooting.shakepay.list1").split(";")
        const list2 = this.$t("faqTab.troubleshooting.shakepay.list2").split(";")
        const list3 = this.$t("faqTab.troubleshooting.shakepay.list3").split(";")
        const list4 = this.$t("faqTab.troubleshooting.shakepay.list4").split(";")
        const list5 = this.$t("faqTab.troubleshooting.shakepay.list5").split(";")

        return {
            list1,
            list2,
            list3,
            list4,
            list5,
        }

    }
};
</script>

<style scoped>
@import "../../components/styles/faq-guides.css";
</style>
