<template>
    <GuidesLayout :header="$t('faq.kodi.howToInstall')" :subtext="$t('faq.kodi.howToInstall')">
        <GuidesStep v-for="step in stepsKodi" :key="step.id" :stepHeader="step.title" :stepNumber="step.id"
            :hideIcon="step.hideIcon">
            <component :is="step.content" />
        </GuidesStep>
        <div class="mt-10">
            <h1 class="guide-header">{{ $t('faq.kodi.howToInstall') }}</h1>
            <GuidesStep :stepHeader="$t('faq.kodi.step5.hereIsTheGuide')" :stepNumber="1">
                <Step5 />
            </GuidesStep>
        </div>
    </GuidesLayout>
</template>

<script>
export default {
    components: {
        GuidesLayout: () => import('@/layouts/GuidesLayout.vue'),
        GuidesStep: () => import('@/components/GuidesStep.vue'),
        Step5: () => import('./components/Step5.vue')
    },
    data() {
        return {
            stepsKodi: [
                {
                    id: 1,
                    title: this.$t('faq.kodi.steps.step1'),
                    hideIcon: true
                },
                {
                    id: 2,
                    title: this.$t('faq.kodi.steps.step2'),
                    hideIcon: true
                },
                {
                    id: 3,
                    title: this.$t('faq.kodi.steps.step3'),
                    hideIcon: true
                },
                {
                    id: 4,
                    title: this.$t('faq.kodi.steps.step4'),
                    content: () => import('./components/Step4.vue')
                }
            ]
        }
    }
};
</script>
