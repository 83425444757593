import Aigle from 'aigle';
import moment from 'moment';
import _ from 'lodash';

let vueServiceContainer;

class VueService {
	/** @type {import('lodash').LoDashStatic} */
	_ = _;

	/** @type {import('aigle').default} */
	Aigle = Aigle;

	/** @type {import('moment')} */
	moment = moment;

	/** @type {import('vuex').Store} */
	store;

	router;
	#messageSystem;
	#componentsContext;

	loadVueInstance = (vueInstance) => {
		this.#componentsContext = require.context('@/components/', true, /.vue$/);
		this.#messageSystem = vueInstance.messageSystem;
		this.store = vueInstance.$store;
		this.router = vueInstance.$router;
	};

	loadComponent = (componentName) => {
		const componentsContextKeys = this.#componentsContext.keys();
		const componentsContextKey = _.find(componentsContextKeys, (componentsContextKey) => this.#componentsContext(componentsContextKey).default.name === componentName);
		if (!componentsContextKey) {
			return {};
		}
		const component = this.#componentsContext(componentsContextKey).default;
		return component;
	};

	loadComponents = (componentNames) => {
		return _(this.#componentsContext.keys())
			.filter((componentsContextKey) => {
				return _.includes(componentNames, this.#componentsContext(componentsContextKey).default.name);
			})
			.transform((result, componentsContextKey) => {
				const component = this.#componentsContext(componentsContextKey).default;
				result[component.name] = component;
			}, {});
	};

	showToastMessage = ({ message, type, time, position, items }) => this.#messageSystem.addMessage({ text: message, type, timeout: time, position, items });
}

/** @returns {VueService} */
const initVueService = () => {
	if (!vueServiceContainer) {
		vueServiceContainer = new VueService();
	}
	return vueServiceContainer;
};

export default initVueService();
