<template>
    <div>
        <FAQHeader />
        <div class="faq-guide">
            <h1 class="main-header mb-4">{{ $t("faqTab.troubleshooting.coinsmart.header") }}</h1>
            <h2 class="second-header mb-2">{{ $t("faqTab.troubleshooting.coinsmart.steps") }}</h2>
            <ul class="list-style mb-4">
                <li v-for="item in list1" :key="item">
                    <p class="main-text">
                        {{ item }}
                    </p>
                </li>
            </ul>
            <p class="main-text mb-4">{{ $t("faqTab.troubleshooting.coinsmart.thankYou") }}</p>
            <FAQGuideFooter guideText="faqTab.troubleshooting.coinsmart.guide"/>
        </div>
    </div>

</template>

<script>
export default {
    components: {
        FAQHeader: () => import('@/components/FAQHeader.vue'),
        FAQGuideFooter: () => import('@/components/FAQGuideFooter.vue')
    },
    data() {
        const list1 = this.$t("faqTab.troubleshooting.coinsmart.list1").split(";")
        const list2 = this.$t("faqTab.troubleshooting.coinsmart.list2").split(";")

        return {
            list1,
            list2
        }

    }
};
</script>

<style scoped>
@import "../../components/styles/faq-guides.css";
</style>
