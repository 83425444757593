<template>
    <PremiumClubLayout v-if="brandName === 'PremiumClub'">
        <PremiumClubsApps />
    </PremiumClubLayout>
    <DefaultLayout v-else>
        <Default />
    </DefaultLayout>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    components: {
        Default: () => import('./components/Default/AppsDefault.vue'),
        DefaultLayout: () => import('@/layouts/DefaultLayout.vue'),
        PremiumClubLayout: () => import('@/layouts/PremiumClubLayout.vue'),
        PremiumClubsApps: () => import('./components/PremiumClub/PremiumApps.vue')
    },
    computed: {
        ...mapGetters(['brandName'])
    }
};
</script>
