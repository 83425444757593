<template>
	<Wrapper :heading="$t('global.changePassword')">
		<div class="row">
			<div class="col-md-6">
				<v-row>
					<v-text-field 
						class="password-input" 
						color="white" 
						v-model="processedPassword"
						:rules="[rules.required, rules.min, rules.max, rules.special, rules.space, rules.resetVerification]"	
						:label="$t('global.enterNewPass')" 
						:outlined="true" 
					/>
					<v-text-field 
						class="password-input" 
						color="white" 
						v-model="processedPasswordVerification"
						:rules="[rules.required, rules.matchingPassword]"
						:label="$t('global.enterNewPassAgain')" 
						:outlined="true" 
					/>
				</v-row>
				<v-row>
					<v-col>
						<button class="btn btn-sm btn-danger px-3" @click="changePassword">{{ $t('global.continue')
						}}</button>
					</v-col>
				</v-row>
			</div>
		</div>
	</Wrapper>
</template>

<script>

export default {
	props: {
		submit: Function,
		rules: Object,
		password: String,
		passwordVerification: String,
		updatePasswordVerification: Function,
		updatePassword: Function
	},
	computed: {
		processedPassword: {
			get() {
				return this.password;
			},
			set(value) {
				this.updatePassword(value);
			}
		},
		processedPasswordVerification: {
			get() {
				return this.passwordVerification;
			},
			set(value) {
				this.updatePasswordVerification(value);
			}
		}
	},
	methods: {
		changePassword() {
			this.submit(this.password)
			this.$router.push('/Profile');
		}
	},
	components: {
		Wrapper: () => import('@/components/Wrapper.vue')
	}
};
</script>
<style>
.v-input.v-input--is-label-active.v-input--is-dirty.theme--light.v-text-field.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined input {
	color: white !important;
}

.form-check-label::before {
	border-color: #e52744;
}

.mini-plan span:first-child {
	font-size: 18px;
}

.mini-plan {
	font-weight: 600;
}

.mini-plan span:last-child {
	font-size: 24px;
	color: #e52744;
	margin-left: 1rem;
}

.col {
	min-width: 340px;
}
</style>

<style scoped>
.password-input {
	margin: 20px 0 !important;
}
</style>
