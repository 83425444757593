<template>
    <Wrapper :heading="$t('global.transactions')">
        <div class="top-container mb-4">
            <RefreshPayment @click="handleRefreshTransactions" />
        </div>
        <Table :headers="headers" :data="items" />
        <div class="bottom-container">
            <SubscibeInfo />
        </div>
    </Wrapper>
</template>

<script>

export default {
    props: {
        items: Array,
        headers: Array,
        handleRefreshTransactions: Function
    },
    components: {
        Wrapper: () => import('@/components/Wrapper.vue'),
        Table: () => import('@/components/Table.vue'),
        SubscibeInfo: () => import('@/components/SubscibeInfo.vue'),
        RefreshPayment: () => import('@/components/RefreshPayment.vue')
    }
};
</script>

<style scoped>
.bottom-container {
    margin-top: 51px;
}

.bottom-container {
    position: inherit;
}

.top-container {
    position: inherit;
}
</style>
