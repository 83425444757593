<template>
	<PremiumClubLayout v-if="brandName === 'PremiumClub'">
		<PremiumClubTransactions :items="items" :handleRefreshTransactions="handleRefreshTransactions" :headers="headers" :isHeaderActive="true"/>
	</PremiumClubLayout>
	<DefaultLayout v-else>
		<Default :items="items" :handleRefreshTransactions="handleRefreshTransactions" :headers="headers" />
	</DefaultLayout>
</template>

<script>
import moment from 'moment';
import { mapGetters } from 'vuex';
import ApiService from '@/services/ApiService.js';
import _ from 'lodash';

export default {
	components: {
		Default: () => import('./Default/index.vue'),
		DefaultLayout: () => import('@/layouts/DefaultLayout.vue'),

		PremiumClubLayout: () => import('@/layouts/PremiumClubLayout.vue'),
		PremiumClubTransactions: () => import('./PremiumClub/Transactions.vue'),
	},
	data() {
		return {
			items: [],
			headers: [
				this.$t('global.transactionId'),
				this.$t('global.packageName'),
				this.$t('global.type'),
				this.$t('global.amount'),
				this.$t('global.status'),
				this.$t('global.date'),
				this.$t('global.note')
			]
		};
	},
	computed: {
		...mapGetters(['token', 'brandName'])
	},
	methods: {
		async setupData() {
			const loader = this.$loading.show();

			try {
				const transactionsRes = await ApiService.transactionsApi.searchTransactions(this.token);
				this.items = _.map(transactionsRes, (transaction) => {
					return {
						transactionId: transaction.id,
						packageName: transaction.packageName,
						type: transaction.type === 'Deposit' ? this.$t(`global.${transaction.type}`) : this.$t(`global.${transaction.subType}`),
						amount: transaction.amount,
						status: this.$t(`global.${transaction.status}`),
						createdDate: transaction.createdDate ? moment(transaction.createdDate).format('yyyy-MM-DD') : '',
						notes: transaction.notes
					};
				});
				loader.hide();
			} catch (error) {
				localStorage.setItem('redirect', '/Transactions');
				loader.hide();
			}
		},
		async handleRefreshTransactions() {
			await this.setupData();
		}
	},
	async created() {
		await this.setupData();
	}
};
</script>

<style scoped></style>
