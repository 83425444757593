<template>
    <GuidesLayout :header="$t('faq.firestick.subtext.minimumOf')" :subtext="$t('faq.firestick.header')">
        <HeaderSubtext />
        <GuidesStep v-for="step in firstSteps" :key="step.id" :stepHeader="step.title" :stepNumber="step.id">
            <component :is="step.content" />
        </GuidesStep>
        <h1 class="firestick-subtext">{{ $t('faq.firestick.step6.subtext') }}</h1>
        <GuidesStep :stepHeader="$t('faq.firestick.titles.step5-6-7')" :stepNumber="6">
            <Step6 />
        </GuidesStep>
        <h1 class="firestick-subtext">{{ $t('faq.firestick.step7.title') }}</h1>
        <p class="mb-4">{{ $t('faq.firestick.step7.subtext') }}</p>
        <div class="column-container">
            <GuidesStep v-for="step in columnSteps" :key="step.id" :stepHeader="step.title"
                :stepNumber="step.stepNumber">
                <component :is="step.content" />
            </GuidesStep>
        </div>
        <GuidesStep v-for="step in sameComponents" :key="step.id" :stepHeader="step.title" :stepNumber="step.stepNumber">
            <Steps v-if="!step.content" :step="step.stepNumber"/>
            <component v-else :is="step.content" />
        </GuidesStep>
        <GuidesStep v-for="step in lastSteps" :key="step.id" :stepHeader="step.title" :stepNumber="step.stepNumber">
            <component :is="step.content" />
        </GuidesStep>
    </GuidesLayout>
</template>

<script>
export default {
    components: {
        GuidesLayout: () => import('@/layouts/GuidesLayout.vue'),
        GuidesStep: () => import('@/components/GuidesStep.vue'),
        HeaderSubtext: () => import('./components/HeaderSubtext.vue'),
        Step6: () => import('./components/Step6.vue'),
        Steps: () => import('./components/SameStepsComponent.vue')
    },
    computed: {
        firstSteps() {
            return this.steps.slice(0, 5)
        },
        columnSteps() {
            return this.steps.slice(5, 11)
        },
        sameComponents() {
            return this.steps.slice(11, 24)
        },
        lastSteps() {
            return this.steps.slice(24)
        }
    },
    data() {
        return {
            steps: [
                {
                    id: 1,
                    title: this.$t('faq.firestick.titles.step1'),
                    content: () => import('./components/Step1.vue')
                },
                {
                    id: 2,
                    title: this.$t('faq.firestick.titles.step2'),
                    content: () => import('./components/Step2.vue')
                },
                {
                    id: 3,
                    title: this.$t('faq.firestick.titles.step3'),
                    content: () => import('./components/Step3.vue')
                },
                {
                    id: 4,
                    title: this.$t('faq.firestick.titles.step4'),
                    content: () => import('./components/Step4.vue')
                },
                {
                    id: 5,
                    title: this.$t('faq.firestick.titles.step5-6-7'),
                    content: () => import('./components/Step5.vue')
                },
                {
                    id: 7,
                    stepNumber: 7,
                    title: this.$t('faq.firestick.titles.step5-6-7'),
                    content: () => import('./components/Step7.vue')
                },
                {
                    id: 8,
                    stepNumber: 7,
                    title: this.$t('faq.firestick.titles.step7(2)'),
                    content: () => import('./components/Step7(2).vue')
                },
                {
                    id: 9,
                    stepNumber: 8,
                    title: this.$t('faq.firestick.titles.step8'),
                    content: () => import('./components/Step8.vue')
                },
                {
                    id: 10,
                    stepNumber: 8,
                    title: this.$t('faq.firestick.titles.step8(2)'),
                    content: () => import('./components/Step8(2).vue')
                },
                {
                    id: 11,
                    stepNumber: 9,
                    title: this.$t('faq.firestick.titles.step9'),
                    content: () => import('./components/Step9.vue')
                },
                {
                    id: 12,
                    stepNumber: 9,
                    title: this.$t('faq.firestick.titles.step9(2)'),
                    content: () => import('./components/Step9(2).vue')
                },
                {
                    id: 13,
                    stepNumber: 10,
                    title: this.$t('faq.firestick.titles.step10')
                },
                {
                    id: 14,
                    stepNumber: 11,
                    title: this.$t('faq.firestick.titles.step11')
                },
                {
                    id: 15,
                    stepNumber: 12,
                    title: this.$t('faq.firestick.titles.step12')
                },
                {
                    id: 16,
                    stepNumber: 13,
                    title: this.$t('faq.firestick.titles.step13')
                },
                {
                    id: 17,
                    stepNumber: 14,
                    title: this.$t('faq.firestick.titles.step14')
                },
                {
                    id: 18,
                    stepNumber: 15,
                    title: this.$t('faq.firestick.titles.step15')
                },
                {
                    id: 19,
                    stepNumber: 16,
                    title: this.$t('faq.firestick.titles.step16')
                },
                {
                    id: 20,
                    stepNumber: 17,
                    title: this.$t('faq.firestick.titles.step17')
                },
                {
                    id: 21,
                    stepNumber: 18,
                    title: this.$t('faq.firestick.titles.step18')
                },
                {
                    id: 22,
                    stepNumber: 19,
                    title: this.$t('faq.firestick.titles.step19')
                },
                {
                    id: 23,
                    stepNumber: 20,
                    title: this.$t('faq.firestick.titles.step20')
                },
                {
                    id: 24,
                    stepNumber: 21,
                    title: this.$t('faq.firestick.titles.step21')
                },
                {
                    id: 25,
                    stepNumber: 22,
                    title: this.$t('faq.firestick.titles.step22'),
                    content: () => import('./components/Step22.vue')
                },
            ],
        }
    }
};
</script>

<style scoped>
.firestick-subtext {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;

    font-size: 20px;
    font-weight: 600;
    line-height: 25.2px;

    word-wrap: break-word;
    margin-bottom: 16px;
}

.column-container {
    display: grid;
    grid-template-columns: repeat(2, 49%);
    grid-gap: 25px;
}

@media only screen and (max-width: 768px) {
    .column-container {
        display: block;
    }
}
</style>
