<template>
	<div :class="brandName === 'PremiumClub' ? 'bg-template-pc' : 'bg-template'">
		<!-- <img
      class="bg-template__image"
      src="/background.jpg"
      alt="Hero"
    /> -->
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	name: "BgTemplate",
	computed: {
		...mapGetters(['brandName'])
	},
	data() {
		return {
		}
	},
};
</script>

<style scoped>
.bg-template-pc {
	position: relative;
}

.bg-template-pc::before {
	content: "";
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: -1;
}

.bg-template-pc {
	left: 0;
	overflow: hidden;
	position: absolute;
	bottom: 0;
	right: 0;
	z-index: -2;
	background-image: url(./assets/bg-image.jpg);
	background-position: top 28% right 50%;
	background-repeat: no-repeat;
	background-size: cover;

	top: -100px;
}

.bg-template {
	left: 0;
	overflow: hidden;
	position: absolute;
	bottom: 0;
	right: 0;
	top: -70px;
	z-index: -2;
}

@media only screen and (min-width: 950px) {

	.bg-template {
		top: -100px;
	}
}

.bg-template:after {
	content: "";
	background-color: rgba(0, 0, 0, 0.4);
	background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 0, transparent 60%, rgba(0, 0, 0, 0.8));
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	top: 0;
}

.bg-template__image {
	height: 100%;
	object-fit: cover;
	width: 100%;
}

.bg-template__image img {
	max-width: 100%;
}
</style>
