/* eslint-disable */
import { apiRequest } from './api.js';

const BASE_URL = '/api/public';

const searchTransactions = (token) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/searchTransactions`, body: { token } });

const getPaymentLinkByTransactionId = (transactionId, token) =>
	apiRequest({ method: 'post', endpoint: `${BASE_URL}/getPaymentLinkByTransactionId`, body: { transactionId, token } });

export default { searchTransactions, getPaymentLinkByTransactionId };
