<template>
    <div>
        <h2 class="second-header mb-2">{{ $t(guideText) }}</h2>
        <ul class="list-style mb-4">
            <li v-for="(item, index) in list" :key="item">
                <p class="main-text">
                    {{ item }}
                    <a class="link-text" :href="loginLink" v-if="index === 0" target="_blank">{{ loginLink }}</a>
                </p>
            </li>
        </ul>
        <p class="main-text">{{ $t("faqTab.troubleshooting.regards") }}</p>
        <p class="main-text">{{ $t("faqTab.troubleshooting.support") }}</p>
    </div>

</template>

<script>
import { mapGetters } from "vuex"

export default {
    props: {
        guideText: String
    },
    computed: {
        ...mapGetters(['brandConfig']),
        loginLink() {
            return `${this.brandConfig.portalLink}/Login`
        }
    },
    data() {
        const list = this.$t("faqTab.troubleshooting.miniGuidesList").split(";")

        return {
            list
        }

    }
};
</script>

<style scoped>
@import "./styles/faq-guides.css";
</style>
