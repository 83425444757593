<template>
    <PremiumClubPrivacyPolicy :data="data"/>
</template>

<script>
import {getPrivacyPolicy} from './mock/privacy-policy.js'
export default {
    components: {
        PremiumClubPrivacyPolicy: () => import('./PremiumClub/PrivacyPolicy.vue')
    },
    data() {
        return {
            data: {}
        }
    },
    async created() {
        this.data = await getPrivacyPolicy()
    }
};
</script>
<style scoped>
</style>
