<template>
    <div>
        <FAQHeader />
        <div class="faq-guide">
            <h1 class="main-header mb-4">{{ $t("faqTab.troubleshooting.cashApp.header") }}</h1>
            <h2 class="second-header mb-2">{{ $t("faqTab.troubleshooting.cashApp.steps") }}</h2>
            <ul class="list-style mb-4">
                <li v-for="item in list1" :key="item">
                    <p class="main-text">
                        {{ item }}
                    </p>
                </li>
            </ul>
            <p class="main-text">{{ $t("faqTab.troubleshooting.cashApp.link") }}</p>
            <a class="link-text mb-4" :href="guideLink" target="_blank">{{ guideLink }}</a>
            <FAQGuideFooter guideText="faqTab.troubleshooting.cashApp.guide"/>
        </div>
    </div>

</template>

<script>
export default {
    components: {
        FAQHeader: () => import('@/components/FAQHeader.vue'),
        FAQGuideFooter: () => import('@/components/FAQGuideFooter.vue')
    },
    data() {
        const list1 = this.$t("faqTab.troubleshooting.cashApp.list").split(";")

        return {
            list1,
            guideLink: "https://docs.google.com/document/d/1fGLPtJAkkSxpIO6shO-VIZ6yApA8kNYDZp2aev66hmA/edit#heading=h.efa3zriebj92"
        }

    }
};
</script>

<style scoped>
@import "../../components/styles/faq-guides.css";
</style>
