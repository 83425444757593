<template>
	<div class="forgot">
        <div class="forgot-success" v-if="passSent">
            <img class="forgot-logo" src="@/components/assets/logoPC.svg" alt="">
            <h1 class="success-header">Success</h1>
            <p class="success-subtitle">We’ve sent you an email with instruction for resetting your password. Please check your inboxes!</p>
            <button class="success-btn" @click="goToLoginPage">Go to login</button>
        </div>
        <div class="forgot-container" v-else>
            <img class="forgot-logo" src="@/components/assets/logoPC.svg" alt="">
            <h1 class="forgot-title">{{ $t('generalTabs.forgotPassword') }}</h1>
            <p class="forgot-subtitle">{{ $t('generalTabs.enterEmail') }}</p>
            <div class="forgot-input-container">
                <label class="forgot-input-text">{{ $t("global.email") }}</label>
                <input v-model="email" class="forgot-input" :class="isFormValid === 'Invalid e-mail' && email ? 'border-red' : ''" type="text" required>
                <span v-if="isFormValid === 'Invalid e-mail' && email" class="error-text">{{ $t('generalTabs.fieldsMustContain') }}</span>
            </div>
            <div class="forgot-btns-container">
                <button @click="goToLoginPage" class="forgot-btn">{{ $t("global.cancel") }}</button>
                <button @click="submit(email)" :class="isFormValid === 'Invalid e-mail' || !isFormValid ? 'forgot-pass-inactive-btn' : ''" class="forgot-btn">{{ $t("generalTabs.reset") }}</button>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        goToLoginPage: Function,
        submit: Function,
        rules: Object,
        passSent: Boolean,
		isFormValidProp: Boolean
    },
	data() {
		return {
			email: '',
            isFormValid: this.isFormValidProp
		};
	},
    watch: {
        email(value) {
            this.isFormValid = this.rules.email(value)
        }
    },
};
</script>
<style scoped>
h1,
p,
button,
input,
label {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
    padding: 0;
}
.forgot {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.forgot-container, .forgot-success {
    max-width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    background-color: #1F212799;
    backdrop-filter: blur(10px);
    padding: 60px 32px;
    border-radius: 12px;
}
h1,
p,
button,
input,
label {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
    padding: 0;
}
.success-header {
    font-size: 28px;
    font-weight: 700;
    line-height: 35.28px;
    margin-bottom: 16px;
}
.success-subtitle {
    font-size: 13px;
    font-weight: 400;
    line-height: 19.5px;
    margin-bottom: 24px;
}
.success-btn {
    width: 100%;
    border: none;
    outline: none;
    background-color: #4E4BCD;
    font-weight: 600;
    font-size: 14px;
    line-height: 17.64px;
    border-radius: 8px;
    padding: 12px 0;
    transition: 0.2s all;
}

.forgot-page {
    display: flex;
    justify-content: center;
    align-items: center;

    height: 100vh;
}

.forgot-container {
    max-width: 450px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    background-color: #1F212799;
    backdrop-filter: blur(10px);
    padding: 60px 32px;
    border-radius: 12px;
}

.forgot-logo {
    width: 104px;
    margin: 0 auto;
    margin-bottom: 24px;
}

.forgot-container h1 {
    font-weight: 700;
    font-size: 28px;
    line-height: 35.28px;
    margin-bottom: 16px;
}

.forgot-container .forgot-subtitle {
    font-weight: 400;
    font-size: 13px;
    line-height: 19.5px;
    margin-bottom: 24px;
}

.forgot-input-container label {
    font-weight: 400;
    font-size: 13px;
    line-height: 16.38px;
    margin-bottom: 8px;
}

.forgot-input-container {
    margin-bottom: 24px;
}

.forgot-input {
    width: 100%;
    padding: 12px 16px;
    border: 1px solid #FFFFFF1A;
    border-radius: 8px;
    outline: none;
    background-color: #FFFFFF0A;
    backdrop-filter: blur(10px);


    font-weight: 400;
    font-size: 13px;
    line-height: 16.38px;
    margin-bottom: 8px;
}
.forgot-input.pass {
    margin-bottom: 8px;
}
.forgot-input:focus {
    border: 1px solid #4E4BCD;
}

.forgot-input-forgot {
    text-align: end;
    text-decoration: none;
    color: white;
    cursor: pointer;
}

.forgot-btns-container {
    display: flex;
    justify-content: space-between;
}

.forgot-btn {
    width: 48%;
    border: none;
    outline: none;
    background-color: #4E4BCD;
    font-weight: 600;
    font-size: 14px;
    line-height: 17.64px;
    border-radius: 8px;
    padding: 12px 0;
    transition: 0.2s all;
}

.forgot-pass-inactive-btn {
    background-color: #3C3A46;
    color: #1D1B29;
    pointer-events: none;
}
.error-text {
    color: #FF254C;
    font-size: 13px;
    font-weight: 400;
    line-height: 16.38px;
}
.border-red:focus {
    border: 1px solid #FF254C;
}
@media only screen and (max-width: 425px) {
	.forgot-container {
        max-width: auto;
        margin: 10px;
    }
    .forgot-container h1 {
        font-size: 26px;
    }
}

@media only screen and (max-width: 375px) {
	
}
</style>
