/* eslint-disable */
import _ from 'lodash';
import superAgent from 'superagent';
import { apiRequest } from './api.js';

const BASE_URL = '/api/public';

const selectPaymentMethod = (transactionId, paymentMethodId) =>
	apiRequest({ method: 'post', endpoint: `${BASE_URL}/selectPaymentMethod`, body: { transactionId, paymentMethodId } });

const getCountries = () => apiRequest({ method: 'get', endpoint: `${BASE_URL}/getCountries` });

const getBrandConfig = () => apiRequest({ method: 'post', endpoint: `${BASE_URL}/getBrandConfig` });

const getSiteInfo = () => apiRequest({ method: 'get', endpoint: `${BASE_URL}/getSiteInfo` });

const getLead = (leadId) => apiRequest({ method: 'get', endpoint: `${BASE_URL}/getLead?leadId=${leadId}` });

const getPackagesList = (token, promoId) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/getPackagesList`, body: { token, promoId } });

const getTrialPromo = (token) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/getTrialPromo`, body: { token } });

const getPaymentPage = (token, packageId) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/getPaymentPage`, body: { packageId, token } });

const applyTrialPromoAndGetPaymentPage = (token, packageId, promoCode) =>
	apiRequest({ method: 'post', endpoint: `${BASE_URL}/applyTrialPromoAndGetPaymentPage`, body: { token, promoCode, packageId } });

const sendPhoneVerificationCodeAndCreateLead = (leadData, googleCaptcha) =>
	apiRequest({ method: 'post', endpoint: `${BASE_URL}/sendPhoneVerificationCodeAndCreateLead`, body: { leadData, googleCaptcha } });

const downloadVpn = (token) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/downloadVpn`, body: { token } });

const downloadOpenVpn = (token) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/downloadOpenVpn`, body: { token } });

const getCurrentClientCountryIp2c = async () => {
	try {
		const res = await superAgent.get('https://ip2c.org/s');

		return {
			code: _.get(_.split(res.text, ';')[1], 'US'),
			name: _.get(_.split(res.text, ';')[3], 'US')
		};
	} catch {
		return null;
	}
};

const getCurrentClientCountryDbIp = async () => {
	try {
		const res = await superAgent.get('https://api.db-ip.com/v2/free/self');
		return {
			code: _.get(res, 'body.countryCode', 'US'),
			name: _.get(res, 'body.countryName', 'US'),
			state: _.get(res, 'body.stateProv', null)
		};
	} catch {
		return null;
	}
};

const getCurrentClientCountryFreeIpApi = async () => {
	try {
		const res = await superAgent.get('https://freeipapi.com/api/json');
		return {
			code: _.get(res, 'body.countryCode', 'US'),
			name: _.get(res, 'body.countryName', 'US'),
			state: _.get(res, 'body.regionName', null)
		}
	} catch {
		return null;
	}
}

const switchToPremiumClubBrand = async (subSource, username, token)=> apiRequest({ method: 'post', endpoint: `${BASE_URL}/switchToPremiumClubBrand`, body: { subSource, username, token } });

const isPermittedToPremium = async (token)=> apiRequest({ method: 'post', endpoint: `${BASE_URL}/isPermittedToPremium`, body: { token } });

const getCurrentClientIp = async () => {
	try {
		const res = await superAgent.get('https://api.ipify.org/?format=json');

		return _.get(res, 'body.ip', null);
	} catch {
		return null;
	}
};

const createReferralPhone = (token, referralInfo) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/createReferralPhone`, body: { ...referralInfo, token } });

const isAllowToRegisterByLinkId = (invitationToken) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/isAllowToRegisterByLinkId`, body: { invitationToken } });

const createInvitationLink = (token) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/createInvitationLink`, body: { token } });

const allowLeadRegistration = (email, phoneNumber, isTrial, username) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/allowLeadRegistration`, body: { email, phoneNumber, isTrial, username } });

const retrieveInfo = (token, email) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/retrieveInfo`, body: { token, email } });

const getPixelId = (linkId) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/getPixelId`, body: { linkId } });

const verifyChatUser = (brand, token, userChatId) => apiRequest({ method: 'post', endpoint: `${BASE_URL}/verifyChatUser`, body: { brand, token, userChatId } });

export default {
	selectPaymentMethod,
	getCountries,
	sendPhoneVerificationCodeAndCreateLead,
	getPackagesList,
	getTrialPromo,
	getPaymentPage,
	getCurrentClientCountryIp2c,
	getCurrentClientCountryDbIp,
	getCurrentClientCountryFreeIpApi,
	getCurrentClientIp,
	downloadVpn,
	switchToPremiumClubBrand,
	isPermittedToPremium,
	downloadOpenVpn,
	getSiteInfo,
	createReferralPhone,
	isAllowToRegisterByLinkId,
	createInvitationLink,
	getLead,
	allowLeadRegistration,
	getBrandConfig,
	retrieveInfo,
	getPixelId,
	verifyChatUser,
	applyTrialPromoAndGetPaymentPage
};
