<template>
	<PremiumClubRegister v-if="brandName === 'PremiumClub'" :isTrial="isTrial" :goToRegistrationPage="goToRegistrationPage"
		:isFormValid="isFormValid" :updateIsFormValid="(value) => isFormValid = value" :leadId="leadId"
		:firstName="firstName" :updateFirstName="(value) => firstName = value" :lastName="lastName"
		:updateLastName="(value) => lastName = value" :email="email" :updateEmail="(value) => email = value"
		:emailSuggestion="emailSuggestion" :username="username" :updateUsername="(value) => username = value"
		:isUsernameValid="isUsernameValid" :phone="phone" :updatePhone="(value) => phone = value"
		:phoneFormatValidate="phoneFormatValidate" :clientIpCountry="clientIpCountry"
		:isPhoneFormatValid="isPhoneFormatValid" :country="country" :updateCountry="(value) => country = value"
		:countries="countries" :reff="reff" :updateReff="(value) => reff = value" :promoCode="promoCode"
		:updatePromoCode="(value) => promoCode = value" :submit="submit" :cancel="cancel" />
	<NoNavLayout noside v-else>
		<DefaultRegister :isTrial="isTrial" :goToRegistrationPage="goToRegistrationPage" :isFormValid="isFormValid"
			:updateIsFormValid="(value) => isFormValid = value" :leadId="leadId" :firstName="firstName"
			:updateFirstName="(value) => firstName = value" :lastName="lastName"
			:updateLastName="(value) => lastName = value" :email="email" :updateEmail="(value) => email = value"
			:emailSuggestion="emailSuggestion" :username="username" :updateUsername="(value) => username = value"
			:isUsernameValid="isUsernameValid" :phone="phone" :updatePhone="(value) => phone = value"
			:phoneFormatValidate="phoneFormatValidate" :clientIpCountry="clientIpCountry"
			:isPhoneFormatValid="isPhoneFormatValid" :country="country" :updateCountry="(value) => country = value"
			:countries="countries" :countryState="countryState" :updateCountryState="(value) => countryState = value"
			:states="states" :reff="reff" :updateReff="(value) => reff = value" :promoCode="promoCode"
			:updatePromoCode="(value) => promoCode = value" :submit="submit" :cancel="cancel" />
	</NoNavLayout>
</template>

<script>
import Mailcheck from 'mailcheck';
import ApiService from '@/services/ApiService.js';
import VueService from '@/services/VueService.js';
import affiliates from '@/utils/affiliates';
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { removeDiacritics } from '@/utils/diacritics'

export default {
	components: {
		NoNavLayout: () => import('@/layouts/NoNavLayout.vue'),
		DefaultRegister: () => import('./components/Default/DefaultRegister.vue'),
		PremiumClubRegister: () => import('./components/PremiumClub/Register.vue')
	},
	data() {
		return {
			clientIpPromise: ApiService.publicApi.getCurrentClientIp(),
			mSite: null,
			finishedRegistration: false,
			phone: null,
			emailSuggestion: null,
			firstName: null,
			lastName: null,
			username: null,
			promoCode: null,
			subSource: null,
			referralCode: null,
			leadId: null,
			affiliateId: null,
			funnelId: null,
			email: null,
			zipCode: null,
			passwordVerify: null,
			password: null,
			isFormValid: false,
			isPhoneFormatValid: false,
			showPass: false,
			country: null,
			countryState: null,
			clientIpCountry: '',
			pr: null,
			reff: null,
			isTrial: false,
			alreadyExist: null,
			leadData: null,
			isUsernameValid: true,
			countries: [],
			states: [],
			sources: [
				{
					value: 'searched',
					text: 'Searched Online'
				},
				{
					value: 'Social media',
					text: 'Social Media'
				},
				{
					value: 'friend',
					text: 'From a friend'
				},
				{
					value: 'email',
					text: 'Email'
				}
			],
			hideWarning: this.$route.query.hideWarning === 'true',
			linkId: null,
			sourceId: null
		};
	},
	mounted() {
		const queryStringParams = Object.fromEntries(new URLSearchParams(window.location.search));
		if (queryStringParams.leadId) {
			ApiService.publicApi.getLead(queryStringParams.leadId).then((lead) => {
				// this.phone = lead?.phone;
				this.firstName = lead?.firstName;
				this.lastName = lead?.lastName;
				this.promoCode = lead?.promoId;
				this.country = lead?.country;
				this.countryState = lead?.countryState;
				this.subSource = lead?.subSource;
				// this.email = lead?.email;
			});
			this.leadId = queryStringParams.leadId;
		}
	},
	computed: {
		...mapGetters(['siteInfo', 'brandName']),
		loadedAsIframe() {
			return window.self !== window.top;
		}
	},
	methods: {
		updateEmail(email) {
			this.email = email;
			this.emailSuggestion = null;
		},
		validateUsername(username) {
			const validUsername = /^[0-9A-Za-z_/-]{6,50}$/;
			if (validUsername.test(username)) {
				this.isUsernameValid = true;
			} else {
				this.isUsernameValid = false;
			}
		},
		phoneFormatValidate(formattedNumber, { number, valid }) {
			if (valid) {
				this.isPhoneFormatValid = true;
				this.phone = number;
			} else {
				this.isPhoneFormatValid = false;
			}
		},
		async submit() {
			const pattern = /^(([^<>()+[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@+"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if (!pattern.test(this.email)) {
				this.emailSuggestion = this.$t('global.invalidEmail');
				return;
			}

			const loader = this.$loading.show();
			const alreadyExist = await ApiService.publicApi.allowLeadRegistration(this.email, this.phone, this.isTrial, this.username);
			if (alreadyExist) {
				this.alreadyExist = alreadyExist;
				loader.hide();
				return
			}
			const clientIp = await this.clientIpPromise;
			const clientData = {
				phone: this.phone,
				firstName: this.firstName,
				promoCode: this.promoCode,
				referralCode: this.referralCode,
				affiliateId: isNaN(this.affiliateId) ? null : parseInt(this.affiliateId),
				lastName: this.lastName,
				username: this.username,
				email: this.email,
				country: this.country,
				countryState: this.countryState,
				leadId: this.leadId,
				isLead: this.leadId,
				isTrial: this.isTrial,
				ip: clientIp,
				mSite: this.mSite,
				subSource: this.subSource,
				funnelId: this?.funnelId,
				pr: this.pr,
				reff: this.reff,
				linkId: this.linkId,
				sourceId: this.sourceId
			};
			const queryStringParams = Object.fromEntries(new URLSearchParams(window.location.search));
			loader.hide();
			this.$router.push({ name: 'PhoneValidation', params: clientData, query: queryStringParams });
		},
		cancel() {
			if (this.loadedAsIframe) {
				const queryStringParams = Object.fromEntries(new URLSearchParams(window.location.search));
				window.parent.postMessage("close", queryStringParams?.originTarget);
			} else {
				this.$router.push({ name: 'Login' });
			}
		},
		goToRegistrationPage() {
			this.isTrial = false;
		},
		getStatesFromCountry(countryCode) {
			const country = this.countries.find(c => c.value == countryCode) || { states: [] };
			return country.states.map(state => ({
				text: state.name,
				value: `${country.value}_${state.code}`
			}));
		}
	},

	async created() {
		const loader = this.$loading.show();
		const queryStringParams = Object.fromEntries(new URLSearchParams(window.location.search));
		const clientData = this.$route.params;

		if (queryStringParams.referral) {
			this.promoCode = queryStringParams.referral;
		}

		this.linkId = queryStringParams?.linkId;
		this.sourceId = queryStringParams?.sourceId;
		this.referralCode = queryStringParams.referral_code;
		if ((this.linkId && this.sourceId) && !this.referralCode) {
			this.referralCode = await affiliates.setTrackDeskCdn().catch((err) => console.error(err));
		}
		if (queryStringParams.firstName) {
			this.email = queryStringParams.email;
			this.username = queryStringParams.username;
			this.phone = queryStringParams.phone;
			this.firstName = queryStringParams.firstName;
			this.lastName = queryStringParams.lastName;
			this.promoCode = queryStringParams.promoCode;
			this.referralCode = queryStringParams.referral_code;
			this.country = queryStringParams.country;
			this.countryState = queryStringParams.countryState;
		}

		if (clientData.username) {
			this.email = clientData.email;
			this.username = clientData.username;
			this.phone = clientData.phone;
			this.firstName = clientData.firstName;
			this.promoCode = clientData.promoCode;
			this.reff = clientData.reff;
			this.lastName = clientData.lastName;
			this.country = clientData.country;
			this.countryState = clientData.countryState;
			if (clientData.captchaErr) {
				VueService.showToastMessage({ message: this.$t('global.pleaseTryAgain'), type: 'error' });
			}

			if (clientData.alreadyExist) {
				if (clientData.byPhone || clientData.byEmail) {
					VueService.showToastMessage({
						message: `${clientData.byEmail ? this.$t('global.yourEmail') : this.$t('global.phoneNumber')} ${this.$t('global.alreadyExist')}`,
						type: 'error'
					});
				}
				if (clientData.error101) {
					VueService.showToastMessage({
						message: this.$t('global.error101'),
						type: 'error'
					});
				}
				if (clientData.byUsername) {
					VueService.showToastMessage({
						message: `${this.$t('global.byUsername')} ${this.$t('global.alreadyExist')}`,
						type: 'error'
					});
				}
			}
		}

		this.isTrial = queryStringParams.trial || (this.$route.params.trial === 'true');
		if (_.includes(window.location.host, 'checkout')) {
			this.mSite = true;
		}
		this.pr = queryStringParams.pr;
		const countriesRes = await ApiService.publicApi.getCountries();

		let mappedCountries = _(countriesRes)
			.map((country) => ({ text: country.name, value: country.code, states: country.states }))
			.sortBy((displayItem) => displayItem.text)
			.value();

		if (this.mSite) {
			const restrictedCountries = [
				'Belarus',
				'Central African Republic',
				'Congo (Brazzaville)',
				'Congo (Kinshasa)',
				'Cuba',
				'Iran',
				'Iraq',
				'Lebanon',
				'Libya',
				'North Korea',
				'Somalia',
				'South Sudan',
				'Syria',
				'Venezuela',
				'Yemen',
				'Zimbabwe',
				'Sudan',
				'Ivory Coast',
				'Myanmar',
				'Liberia',
				'Russia',
				'Ukraine',
				'Hong Kong',
				'Afghanistan',
				'Ethiopia',
				'Mali',
				'Nicaragua'
			];
			mappedCountries = _.omitBy(mappedCountries, (country) => _.includes(restrictedCountries, country.text));
		}

		this.countries = mappedCountries;
		this.clientIpCountry = await Promise.race([ApiService.publicApi.getCurrentClientCountryDbIp(), ApiService.publicApi.getCurrentClientCountryFreeIpApi()])
		this.country = this.clientIpCountry.code;
		this.states = this.getStatesFromCountry(this.country);
		this.countryState = this.states.find(s => s.text === removeDiacritics(this.clientIpCountry.state))?.value || null;
		loader.hide();
	},
	watch: {
		email(val) {
			const res = Mailcheck.run({ email: val });

			if (res) {
				this.emailSuggestion = res.full;
				return this.$t('global.invalidEmail');
			}

			this.emailSuggestion = null;
		},
		alreadyExist(error) {
			if (error) {
				if (error.byUsername) {
					VueService.showToastMessage({
						message: `${this.$t('global.byUsername')} ${this.$t('global.alreadyExist')}`,
						type: 'error'
					});
					return
				}
				VueService.showToastMessage({
					message: `${error?.byEmail ? `${this.$t('global.yourEmail')} ${this.$t('global.alreadyExist')}` : this.$t('global.tooManyTrials')} `,
					type: 'error'
				});
				return;
			}
		},
		username(val) {
			this.validateUsername(val)
		},
		country(newCountry, oldCountry) {
			if (newCountry !== oldCountry && oldCountry !== null) {
				this.countryState = null;
			}

			this.states = this.getStatesFromCountry(newCountry);
		}
	}
};
</script>

<style>
.trial_over_container {
	color: #fff;
	position: relative;
	top: -116px;
	height: 0;
	text-align: center;
}

.v-input.v-input--hide-details.v-input--is-label-active.v-input--is-dirty.v-input--dense.theme--light.v-text-field.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined.Components-field-2K_U.Components-required-3FxJ.Components-focused-32y0 input {
	color: white !important;
}

.v-input.v-input--has-state.v-input--is-label-active.v-input--is-dirty.v-input--dense.theme--light.v-text-field.v-text-field--is-booted.v-text-field--enclosed.v-text-field--outlined.error--text.Components-field-2K_U.Components-required-3FxJ.Components-focused-32y0 input {
	color: white !important;
}

.theme--light.v-input,
.theme--light.v-label,
.theme--light.v-input textarea,
.theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)>.v-input__control>.v-input__slot fieldset {
	color: rgba(255, 255, 255, 0.486) !important;
	border: none;
	background-color: rgb(39, 51, 63);
}

.theme--light.v-text-field>.v-input__control>.v-input__slot:before {
	border-color: rgba(255, 255, 255, 0.336);
}

.v-text-field--outlined.v-input--dense .v-label--active {
	left: -13px;
	-webkit-transform: translateY(-328px) scale(.75);
	transform: translateY(-28px) scale(.75);
	background-color: transparent;
}

.v-text-field--outlined.v-input--has-state fieldset,
.v-text-field--outlined.v-input--is-focused fieldset {
	border: none;
}

.btn.btn-danger.v-btn--disabled {
	color: white !important;
}

.phone-validator {
	color: white;
}

.default-class {
	color: black !important;
	min-width: 180px !important;
}

.vue-tel-input {
	min-width: 180px !important;
	padding: 5px 0;
	background-color: rgb(39, 51, 63);
	border: none;
}

.default-class:has(.trial_over_container) {
	padding-top: 4em !important;
}

.register-buttons-container {
	display: flex;
	margin-top: 1em;
	width: 100%;
	justify-content: space-between;
}
</style>

<style scoped>
.default-class {
	max-width: 550px;
}

.name-lastname {
	min-width: 100px;
}

.btns {
	width: 45%;
	font-size: 18px;
	color: #5c6167 !important;
	border: 2px solid #5c6167;
	border-radius: 7px;
	padding: 5px 0;
}

.submit-btn {
	background-color: #16a637;
	border: #16a637;
	color: white !important;
}

.disable-btn {
	pointer-events: none;
	color: rgb(255, 255, 255, 0.1) !important;
	border: 2px solid rgb(255, 255, 255, 0.1) !important;
}

.v-btn:not(.v-btn--round).v-size--default {
	height: auto !important;
	padding: 10px 0 !important;
}

.theme--light.v-input {
	min-width: 166px;
}

.red-signature {
	font-size: 14px;
	color: #f15252;
}
</style>
