// import ApiService from '../services/ApiService';

const injectSendpulse = (widgetId) => {
  const src = 'https://cdn.pulse.is/livechat/loader.js';
  const existingScript = document.querySelector(`script[src='${src}']`);
  if (existingScript) {
    return;
  } else {
    const body = document.querySelector('body');
    const script = document.createElement('script');
    script.async = true;
    script.src = src;
    script.setAttribute('data-live-chat-id', widgetId);
    body.appendChild(script);
    return;
  }
};

const setFreshchat = () => {};

const setSendpulse = () => {};

const setLiveChat = () => {};

const setChatState = ({ provider }) => {
  try {
    switch (provider) {
      case 'Sendpulse':
        return setSendpulse();
      case 'freshchat':
        return setFreshchat();
      case 'livechat':
        return setLiveChat();
      default:
        return () => {};
    }
  } catch {
    console.warn(`Cannot load ${provider} chat.`);
  }
};

const getChatProvider = (provider, widgetId) => {
  try {
    switch (provider) {
      case 'Sendpulse':
        return injectSendpulse(widgetId);
      default:
        return () => {};
    }
  } catch {
    console.warn(`Cannot load ${provider} chat.`);
  }
};

export default { getChatProvider, setChatState };
