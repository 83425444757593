<template>
    <GuidesLayout :subtext="$t('faq.flexIptv.flexIptvIs')">
            <h1 class="guide-header">{{ $t('faq.flexIptv.flexIptvAvailable') }}</h1>
            <GuidesStep v-for="step in flexIptvSteps" :key="step.id" :stepHeader="$t(step.title)" :stepNumber="step.id">
                <component :is="step.content" />
            </GuidesStep>
        <div class="iptv-info">
            <h1 class="guide-header">{{ $t('faq.flexIptv.tvguide') }}</h1>
            <GuidesStep v-for="step in tvGuideSteps" :key="step.id" :stepHeader="$t(step.title)" :stepNumber="step.id">
                <component :is="step.content" />
            </GuidesStep>
        </div>
    </GuidesLayout>
</template>

<script>
export default {
    components: {
        GuidesLayout: () => import('@/layouts/GuidesLayout.vue'),
        GuidesStep: () => import('@/components/GuidesStep.vue')
    },
    data() {
        return {
            flexIptvSteps: [
                {
                    id: 1,
                    title: 'faq.flexIptv.titles.step1',
                    content: () => import('./components/flexIptv/Step1.vue')
                },
                {
                    id: 2,
                    title: 'faq.flexIptv.titles.step2',
                    content: () => import('./components/flexIptv/Step2.vue')
                },
                {
                    id: 3,
                    title: 'faq.flexIptv.titles.step3',
                    content: () => import('./components/flexIptv/Step3.vue')
                },
                {
                    id: 4,
                    title: 'faq.flexIptv.titles.step4',
                    content: () => import('./components/flexIptv/Step4.vue')
                },
                {
                    id: 5,
                    title: 'faq.flexIptv.titles.step5',
                    content: () => import('./components/flexIptv/Step5.vue')
                },
            ],
            tvGuideSteps: [
                {
                    id: 1,
                    title: 'faq.flexIptv.tvGuide.titles.step1',
                    content: () => import('./components/tvGuide/Step1.vue')
                },
                {
                    id: 2,
                    title: 'faq.flexIptv.tvGuide.titles.step2',
                    content: () => import('./components/tvGuide/Step2.vue')
                },
                {
                    id: 3,
                    title: 'faq.flexIptv.tvGuide.titles.step3',
                    content: () => import('./components/tvGuide/Step3.vue')
                },
                {
                    id: 4,
                    title: 'faq.flexIptv.tvGuide.titles.step4',
                    content: () => import('./components/tvGuide/Step4.vue')
                },
                {
                    id: 5,
                    title: 'faq.flexIptv.tvGuide.titles.step5',
                    content: () => import('./components/tvGuide/Step5.vue')
                },
            ],
        }
    }
};
</script>

<style scoped>
.iptv-info {
    margin-top: 40px;
}
</style>
