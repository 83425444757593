<template>
    <GuidesLayout :header="$t('faq.emby.setupWithEmby')" :subtext="$t('faq.emby.embyIs')">
        <HeaderSubtext />
        <GuidesStep v-for="step in steps" :key="step.id" :stepHeader="step.title" :stepNumber="step.id">
            <component :is="step.content" />
        </GuidesStep>
    </GuidesLayout>
</template>

<script>
export default {
    components: {
        GuidesLayout: () => import('@/layouts/GuidesLayout.vue'),
        GuidesStep: () => import('@/components/GuidesStep.vue'),
        HeaderSubtext: () => import('./components/HeaderSubtext.vue')
    },
    data() {
        return {
            steps: [
                {
                    id: 1,
                    title: this.$t('faq.emby.titles.step1'),
                    content: () => import('./components/Step1.vue')
                },
                {
                    id: 2,
                    title: this.$t('faq.emby.titles.step2'),
                    content: () => import('./components/Step2.vue')
                },
                {
                    id: 3,
                    title: this.$t('faq.emby.titles.step3'),
                    content: () => import('./components/Step3.vue')
                },
                {
                    id: 4,
                    title: this.$t('faq.emby.titles.step4'),
                    content: () => import('./components/Step4.vue')
                },
                {
                    id: 5,
                    title: this.$t('faq.emby.titles.step5'),
                    content: () => import('./components/Step5.vue')
                },
                {
                    id: 6,
                    title: this.$t('faq.emby.titles.step6'),
                    content: () => import('./components/Step6.vue')
                },
            ]
        }
    }
};
</script>
