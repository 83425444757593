<template>
    <div class="guides-step">
        <div class="guides-step-container" :class="!hideIcon ? 'with-border' : 'without-border'">
            <div class="guides-step-info">
                <div class="guides-step-icon-container">
                    <img class="guides-step-icon" src="./assets/complete.svg" alt="">
                </div>
                <div class="guides-step-header">
                    <p class="step">{{ $t('faq.menu.step') }}{{ stepNumber }}</p>
                    <h1 class="step-header">{{ stepHeader }}</h1>
                </div>
            </div>
            <img v-if="!hideIcon" src="./assets/arrow-insert.svg" alt="">
        </div>
        <slot />
    </div>
</template>

<script>

export default {
    props: {
        stepNumber: Number,
        stepHeader: String,
        hideIcon: Boolean
    }
};
</script>

<style scoped>
h1,
p {
    font-family: 'Wix Madefor Display', sans-serif;
    color: white;
    margin: 0;
}

.guides-step {
    padding: 16px;
    background-color: #1F2127;
    border-radius: 4px;
    width: 100%;
    margin-bottom: 16px;
}

.guides-step-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.without-border {
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
}
.with-border {
    padding-bottom: 16px;
    border-bottom: 1px solid #FFFFFF1A;
    margin-bottom: 16px;
}
.guides-step-info {
    display: flex;
    align-items: center;
}
.guides-step-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #4E4BCD;
    border-radius: 4px;
    margin-right: 4px;
    padding: 9px 9px;
}
.step {
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    color: #FFFFFF66;
}
.step-header {
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    margin-right: 5px;
}
.guides-step-header {
    margin-left: 16px;
}
.screen {
    width: 100%;
}
@media only screen and (max-width: 580px) {
    .step-header {
        font-size: 16px;
        line-height: 16px;
        word-break: break-word;
    }
}
</style>
