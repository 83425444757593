<template>
    <div>
        <FAQHeader />
        <div class="faq-guide">
            <h1 class="main-header mb-4">{{ $t("faqTab.troubleshooting.venmoCrypto.header") }}</h1>
            <p class="main-text mb-2">{{ $t("faqTab.troubleshooting.venmoCrypto.steps") }}</p>
            <h2 class="second-header mb-2">{{ $t("faqTab.troubleshooting.venmoCrypto.transfer") }}</h2>
            <p class="main-text mb-2">{{ $t("faqTab.troubleshooting.venmoCrypto.can") }}</p>
            <ul class="list-style mb-4">
                <li v-for="(item, index) in list1" :key="item">
                    <p class="main-text">
                        {{ item }}
                    </p>
                    <p v-if="index === 1">
                        <span class="link-text d-print-block">
                            {{ $t("faqTab.troubleshooting.venmoCrypto.note") }}
                        </span>
                        {{ $t("faqTab.troubleshooting.venmoCrypto.only") }}
                    </p>
                </li>
                <p class="main-text">{{ $t("faqTab.troubleshooting.venmoCrypto.other") }}</p>
                <p class="main-text mb-2">{{ $t("faqTab.troubleshooting.venmoCrypto.payPal") }}</p>
            </ul>
            <FAQGuideFooter guideText="faqTab.troubleshooting.venmoCrypto.guide"/>
        </div>
    </div>

</template>

<script>
export default {
    components: {
        FAQHeader: () => import('@/components/FAQHeader.vue'),
        FAQGuideFooter: () => import('@/components/FAQGuideFooter.vue')
    },
    data() {
        const list1 = this.$t("faqTab.troubleshooting.venmoCrypto.list1").split(";")
        const list2 = this.$t("faqTab.troubleshooting.venmoCrypto.list2").split(";")

        return {
            list1,
            list2
        }

    }
};
</script>

<style scoped>
@import "../../components/styles/faq-guides.css";
</style>
