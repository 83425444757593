<template>
	<Wrapper :heading="$t('global.profile')">
		<div class="content-wrapper" v-if="clientObj.email">
			<div>
				<div class="row mb-2">
					<div class="col-4 col-lg-2">{{ $t('global.username') }}</div>
					<div class="col-8 col-lg-10 overflow-hidden">{{ clientObj.email }}</div>
				</div>
				<div class="row mb-2">
					<div class="col-4 col-lg-2">{{ $t('global.email') }}</div>
					<div class="col-8 col-lg-10 overflow-hidden">{{ clientObj.clientEmail }}</div>
				</div>
				<div class="row mb-2">
					<div class="col-4 col-lg-2 d-flex align-items-center">
						{{ $t('global.birthday') }}
					</div>
					<div v-if="clientObj && clientObj.birthDate" class="col-8 col-lg-10 overflow-hidden">{{ birthDate }}
					</div>
					<div class="col-8 col-lg-10" v-if="clientObj && !clientObj.birthDate && !isBirthdayChangeVisible">
						<button class="btn btn-secondary" @click="toggleChangeBirthday">{{ $t('global.changeBirthday')
						}}</button>
					</div>
					<div class="col-8 col-lg-10" v-if="isBirthdayChangeVisible">
						<ChangeBirthday :closePicker="toggleChangeBirthday" />
					</div>
				</div>
				<template v-if="!clientObj.mSite">
					<div class="row mb-2">
						<div class="col-4 col-lg-2">{{ $t('global.password') }}</div>
						<div class="col-8 col-lg-10 overflow-hidden">{{ clientObj.password }}</div>
					</div>
					<div class="row mb-2">
						<div class="col-4 col-lg-2">{{ $t('global.playerLink') }}</div>
						<div class="col-8 col-lg-10 overflow-hidden">
							<a :href="clientObj.playerLink" target="_blank">{{ clientObj.playerLink }}</a>
						</div>
					</div>
					<div class="row mb-2">
						<div class="col-4 col-lg-2">{{ $t('global.m3uLink') }}</div>
						<div class="col-8 col-lg-10 overflow-hidden">
							<a :href="subscriptionsObj ? clientObj.m3uLink : '#'" target="_blank">{{
								subscriptionsObj ? clientObj.m3uLink : $t('global.subscribeNowToGet')
							}}</a>
						</div>
					</div>
				</template>
				<div class="row mb-2">
					<div class="col-4 col-lg-2">{{ $t('global.myReferral') }}</div>
					<div class="col-8 col-lg-10">{{ clientObj.id }}</div>
				</div>
				<div class="row mb-2">
					<div class="col-10">{{ $t('global.dontCallMe') }}</div>
					<div class="col-2">
						<v-switch class="switch" v-model="clientObj.dontCallMe" @change="onToggleDontCallMe"
							color="primary">
						</v-switch>
					</div>
				</div>
				<div>
					<Referral />
				</div>
				<div class="bottom-container">
					<SubscibeInfo />
				</div>
			</div>
		</div>
	</Wrapper>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
	props: {
		onToggleDontCallMe: Function,
		createdFunc: Function,
		isBirthdayChangeVisible: Boolean,
		toggleChangeBirthday: Function,
	},
	components: {
		SubscibeInfo: () => import('@/components/SubscibeInfo.vue'),
		Referral: () => import('@/components/Referral.vue'),
		Wrapper: () => import('@/components/Wrapper.vue'),
		ChangeBirthday: () => import('../ChangeBirthday.vue')
	},
	computed: {
		...mapGetters(['subscriptionsObj', 'clientObj']),
		birthDate() {
			if (this.clientObj?.birthDate) {
				return new Date(this.clientObj.birthDate).toLocaleString('en-GB').split(',')[0]
			}

			return null;
		}
	},
	async created() {
		await this.createdFunc()
	}
};
</script>

<style scoped>
.switch {
	background-color: transparent !important;
}

.default-class {
	color: white;
}

.content-wrapper {
	color: white;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
	flex: 1;
}

.content-wrapper .col-lg-2 {
	font-weight: 500;
}

.content-wrapper .col-lg-10 {
	font-weight: 400;
}

.bottom-container {
	position: inherit;
}
</style>