<template>
    <PremiumClubLayout v-if="brandName === 'PremiumClub'">
        <PremiumClubFaq />
    </PremiumClubLayout>
    <DefaultLayout v-else>
        <Wrapper>
            <div class="second-container">
                <h1 class="header">{{$t('global.installationGuidesModules')}}</h1>
                <div class="modules-container">
                    <Module v-for="array in arrayConstructor(brandConfig.faqLinks)" :key="array.id" :array="array"
                        :more="brandConfig.faqLink" />
                </div>
            </div>
        </Wrapper>
    </DefaultLayout>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    components: {
        Wrapper: () => import('@/components/Wrapper.vue'),
        DefaultLayout: () => import('@/layouts/DefaultLayout.vue'),
        Module: () => import('./components/Module.vue'),
        PremiumClubLayout: () => import('@/layouts/PremiumClubLayout.vue'),
        PremiumClubFaq: ()=> import('./components/PremiumClub/PremiumFaq.vue')
    },
    computed: {
        ...mapGetters(['brandName', 'brandConfig'])
    },
    methods: {
        arrayConstructor(array) {
            const titles = [this.$t('global.androidBoxFireStick'), this.$t('global.otherM3U'), this.$t('global.phoneTabletComputer')];
            const result = [];
            for (let i = 0; i < 12; i += 4) {
                const arrayForModule = array.slice(i, i + 4);
                const title = titles[i / 4];
                arrayForModule.unshift(title);
                result.push(arrayForModule);
            }
            return result;
        }
    }
};
</script>

<style scoped>
h1 {
    color: white;
}

.modules-container {
    display: flex;
    justify-content: space-around;
}

.second-container {
    margin-bottom: 50px;
}

.header {
    margin-bottom: 30px;
    text-align: center;
}

@media screen and (max-width: 1280px) {
    .modules-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}
</style>
