<template>
	<div :class="'referral ' + brandName">
		<v-dialog class="dialog-sms" v-model="dialog" width="500" @click:outside="closeModal">
			<v-form v-model="isSmsFormValid">
				<v-card class="dialog-sms__card">
					<v-card-title class="dialog-sms__card__title">
						<div>{{ $t('global.inviteYourFriend') }}</div>
					</v-card-title>
					<v-card-text>
						<div class="col col-12">{{ $t('global.inviteYourFriendDescription') }}</div>
						<div class="col col-12">
							<input-field type="text" color="white" :props="{ styleType: 'outlined' }" v-model="fullName" required :label="$t('global.fullName')" />
						</div>
						<div class="col col-12">
							<vue-tel-input mode="international" @input="phoneFormatValidate" v-model="phone" :input-options="{ placeholder: $t('global.phoneNumber'), styleClasses: 'phone-validator' }" :default-country="clientIpCountry.name">
								<template slot="arrow-icon"> <span class="vti__dropdown-arrow">&nbsp;▼</span></template>
							</vue-tel-input>
							<div class="v-text-field__details" v-if="!isSmsFormValid && _.size(phone) > 0">
								<div class="v-messages theme--dark error--text" role="alert">
									<div class="v-messages__wrapper">
										<div class="v-messages__message">{{ $t('global.invalidPhone') }}</div>
									</div>
								</div>
							</div>
						</div>
					</v-card-text>
					<v-card-actions class="dialog-sms__card__actions">
						<v-spacer></v-spacer>
						<v-btn color="red darken-1" text @click="closeModal">
							{{ $t('global.cancel') }}
						</v-btn>
						<v-btn class="submit-btn" :disabled="!isSmsFormValid || !fullName" color="green darken-1" text @click="sendSms">
							{{ $t('global.submit') }}
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-form>
		</v-dialog>
		<div class="p-3 d-inline-block invite-box">
			<p class="pe-3">
				{{ $t('global.useThatReferral') }}
			</p>
			<img style="width: 100px; margin-right: 25px;" src="https://i.ibb.co/KKDgFx4/949928.png" />
			<button class="btn btn-danger px-3" @click="openSmsDialog">{{ $t('global.sendReferralCodeBySms') }}</button>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import ApiService from '../services/ApiService.js';
import VueService from '../services/VueService.js';

export default {
	data() {
		return {
			dialog: false,
			phone: null,
			fullName: null,
			isSmsFormValid: false,
			clientIpCountry: ''
		};
	},
	computed: {
		...mapGetters(['token', 'brandName'])
	},
	methods: {
		phoneFormatValidate(formattedNumber, { number, valid }) {
			if (valid) {
				this.isSmsFormValid = true;
				this.phone = number;
			} else {
				this.isSmsFormValid = false;
			}
		},
		openSmsDialog() {
			this.dialog = true;
		},
		closeModal() {
			this.dialog = false;
			this.phone = null;
			this.fullName = null;
		},
		async sendSms() {
			const loader = this.$loading.show();
			try {
				const referralInfo = {
					phoneNumberInvited: this.phone,
					fullNameInvited: this.fullName,
					country: this.clientIpCountry?.code
				};
				const res = await ApiService.publicApi.createReferralPhone(this.token, referralInfo);
				if (res && res.msg) {
					VueService.showToastMessage({ message: res.msg, type: 'error' });
					loader.hide();
					return;
				}
				VueService.showToastMessage({ message: this.$t('global.referralSentSucceed'), type: 'success' });
				loader.hide();
				this.closeModal();
			} catch (error) {
				console.error(error);
				VueService.showToastMessage({ message: error.message, type: 'error' });
				loader.hide();
			}
		}
	},
	async created() {
		this.clientIpCountry = await Promise.race([ApiService.publicApi.getCurrentClientCountryDbIp(), ApiService.publicApi.getCurrentClientCountryIp2c()]);
	}
};
</script>

<style>
.invite-box {
	text-align: center;
	border-radius: 8px;
	position: relative;
	font-size: 14px;
	border-style: dashed;
	border-color: rgb(165, 29, 42);
}

.referral.PremiumClub div {
	color: black;
}
.referral.apollogroup,
.referral.pizzatime div {
	color: white;
}

.dialog-sms {
	width: 500px;
	padding: 24px;
}
.dialog-sms__card.v-card.v-sheet.theme--light {
	background-color: #141414;
	color: white;
}
.dialog-sms__card {
	background-color: #141414;
	color: #fff;
}

.dialog-sms__card__title {
	border-bottom: 1px solid rgba(255, 255, 255, 0.12);
	margin-bottom: 18px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.v-card__text {
	color: white !important;
}
.dialog-sms__card__actions .submit-btn:disabled {
	color: rgba(255, 255, 255, 0.26) !important;
}
.phone-validator {
	color: white;
}
.vti__dropdown-list.below {
	color: black !important;
}
.vue-tel-input {
	min-width: 180px !important;
}
</style>
