<template>
	<PremiumClubLayout v-if="brandName === 'PremiumClub'">
		<PremiumClubSubscriptions :packages="packages" :getPaymentPage="getPaymentPage" :headers="headers"
			:translatedSubscriptionsObj="translatedSubscriptionsObj"
			:handleRefreshTransactions="handleRefreshTransactions" />
	</PremiumClubLayout>
	<DefaultLayout v-else>
		<Default :packages="packages" :getPaymentPage="getPaymentPage"
			:handleRefreshTransactions="handleRefreshTransactions" :headers="headers"
			:translatedSubscriptionsObj="translatedSubscriptionsObj" :download="download" :clientObj="clientObj" />
	</DefaultLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ApiService from '@/services/ApiService.js';
import VueService from '@/services/VueService.js';
import _ from 'lodash';

export default {
	components: {
		DefaultLayout: () => import('@/layouts/DefaultLayout.vue'),
		Default: () => import('./components/Default/index.vue'),

		PremiumClubLayout: () => import('@/layouts/PremiumClubLayout.vue'),
		PremiumClubSubscriptions: () => import('./components/PremiumClub/Subscriptions.vue'),
	},
	data() {
		return {
			packages: [],
			headers: [this.$t('global.subscriptionId'), this.$t('global.packageName'), this.$t('global.startDate')]
		};
	},
	computed: {
		...mapGetters(['token', 'transformedSubscriptionsObj', 'clientObj', 'brandName', 'translatedSubscriptionsObj'])
	},
	methods: {
		...mapActions(['setTranslatedSubscriptionsObj']),
		async getPaymentPage(pack) {
			if (!pack) {
				VueService.showToastMessage({ message: this.$t('global.pleaseChoosePackage'), type: 'error' });
				return;
			}
			const packObj = this.packages.find((p) => p.value === pack);
			const loader = this.$loading.show();
			const transaction = await ApiService.publicApi.getPaymentPage(this.token, pack, packObj?.promo?.promoCode);
			loader.hide();

			if (transaction.amount === 0) {
				this.$router.push('ProccessingSubscription');
				return;
			}

			window.location = transaction.paymentUrl;
		},
		async download(type) {
			let file;
			let fileName = '';
			if (type === 'client') {
				window.location = 'https://openvpn.net/downloads/openvpn-connect-v3-windows.msi';
				return;
			} else {
				file = await ApiService.publicApi.downloadVpn(this.token);
				fileName = `${this.clientObj.firstName}.ovpn`;
			}
			const element = document.createElement('a');
			element.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(file.file)}`);
			element.setAttribute('download', fileName);
			document.body.appendChild(element);
			element.click();
		},
		async handleRefreshTransactions() {
			await this.setupData();
		},
		async setupData() {
			const loader = this.$loading.show();
			try {
				this.packages = await ApiService.publicApi.getPackagesList(this.token);
				const subObject = _.map(this.transformedSubscriptionsObj, (sub) => ({
					subscriptionId: sub.subscriptionId,
					packageName: `${sub.packagePeriodInDays} ${this.$t('global.days')}`,
					startDate: sub.startDate
				}));
				this.setTranslatedSubscriptionsObj(subObject)
				loader.hide();
			} catch (error) {
				localStorage.setItem('redirect', '/Subscriptions');
				loader.hide();
			}
		}
	},
	watch: {
		async transformedSubscriptionsObj() {
			await this.setupData()
		}
	}
}
</script>
