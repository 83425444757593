<template>
    <div class="pc-header">
        <div class="pc-header-nav">
            <router-link v-for="link of links" @click.native="handleLogoutClick(link)" :key="link.id"
                :to="link.href === '/Logout' ? '' : link.href" class="nav-link" aria-current="true" :class="{
                active: `/${activeRouteName}` === link.href
            }">
                <span class="nav-name">{{ $t(link.name) }}</span>
            </router-link>
        </div>
        <div @click="toggleActiveBurger">
            <img class="burger-menu-icon" src="./assets/burger.svg" alt="">
        </div>
        <Burger :toggleActiveBurger="toggleActiveBurger" :isActive="isActive" :links="links" />
        <Modal v-if="isModalActive" :closeModal="() => isModalActive = false" :completeLogout="() => $router.push('/Logout')"/>
    </div>
</template>

<script>
import { createLinks } from '@/router/nav-links.js'

export default {
    components: {
        Burger: () => import("./BurgerMenu.vue"),
        Modal: () => import("@/components/PCLogoutModal.vue")
    },
    data() {
        return {
            activeRouteName: this.$router.history.current.name,
            isActive: false,
            links: createLinks(),
            isModalActive: false
        };
    },
    methods: {
        toggleActiveBurger(link) {
            if (link.href !== '/Logout') this.isActive = !this.isActive
            this.handleLogoutClick(link)
        },
        handleLogoutClick(link) {
            if (link.href === '/Logout') {
                this.openModal();
            }
        },
        openModal() {
            this.isModalActive = true
        }
    }
}
</script>

<style scoped>
h1,
p,
button,
input,
label,
span,
a {
    font-family: 'Wix Madefor Display', sans-serif;
    margin: 0;
    padding: 0;
}

.pc-header-nav {
    display: flex;
    width: inherit;
}

.nav-link {
    text-decoration: none;
    border-radius: 16px 16px 0 0;
    padding: 15px 40px;
    display: flex;
    justify-content: center;
    background-color: rgba(31, 33, 39, .6);
    backdrop-filter: blur(10px);
    border: 1px solid #282A30;
    color: rgba(255, 255, 255, 0.4);
    font-size: 16px;
    font-weight: 600;
    line-height: 20.16px;
}

.active {
    background-color: #4E4BCD;
    border: 1px solid #4E4BCD;
    color: white;
}

.burger-menu-icon {
    position: fixed;
    right: 16px;
    top: 22px;
    display: none;
    cursor: pointer;
    z-index: 100;
}
@media only screen and (max-width: 1500px) {
    .nav-link {
        box-sizing: border-box;
        padding: 15px 0;
        font-size: 14px;
        width: 100%;
    }

    .nav-name {
        display: flex;
        align-items: center;
        text-align: center;
    }
}

@media only screen and (max-width: 768px) {
    .burger-menu-icon {
        display: block;
    }

    .pc-header-nav {
        display: none;
    }
}
</style>