<template>
    <div class="position-absolute w-100 vh-100 backdrop d-flex justify-content-center align-items-center"
        v-if="promo && isOpened">
        <div class="popup p-4 text-light text-center w-100">
            <button class="close-icon" @click="closeModal()">
                <Icon class="icon" icon="ant-design:close-outlined" />
            </button>
            <p class="fs-1 mb-1">🎉</p>
            <p class="fs-2 fw-bold lh-sm mb-0">{{ $t('trialPopup.enjoyExclusiveOffer') }} <br /> {{
                $t('trialPopup.forTheNextAmountOfHours', {
                    amountOfHours: promo.hoursForPromo
                }) }}
            </p>
            <p class="fs-5" v-html="$t('trialPopup.unlockSubscription', {
                brandName,
                discount: promo.percentage
            })" />
            <p class="offer-text mb-0">{{ $t('trialPopup.offerAvailableFor') }}</p>
            <div class="d-flex justify-content-center">
                <div v-if="days"><span class="fs-1 timer-number">{{ days }}</span><span class="fs-5">d</span><span
                        class="mx-2 fs-2">:</span></div>
                <div v-if="hours > 0 || days > 0"><span class="fs-1 timer-number">{{ hours }}</span><span
                        class="fs-5">h</span><span class="mx-2 fs-2">:</span></div>
                <div v-if="minutes > 0 || hours > 0 || days > 0"><span class="fs-1 timer-number">{{ minutes }}</span><span
                        class="fs-5">m</span><span class="mx-2 fs-2">:</span></div>
                <div><span class="fs-1 timer-number">{{ seconds }}</span><span class="fs-5">s</span></div>
            </div>
            <div class="d-flex justify-content-center gap-2">
                <div class="sub-card d-flex flex-column px-4 pt-3 pb-4 align-items-center"
                    v-for="promoPackage in  packages " :key="promoPackage.id">
                    <span class="fs-4 fw-bold">${{ promoPackage.discount }}</span>
                    <span class="original-price fs-5 mb-2">${{ promoPackage.price }}</span>
                    <button class="rounded fw-bold px-2" :class="{ [`${brandName}-bg`]: true }"
                        @click="applyPromoAndGetPaymentPage(promoPackage.value)">{{
                            promoPackage.text
                        }}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { Icon } from '@iconify/vue2';
import VueService from '@/services/VueService.js';
import ApiService from '@/services/ApiService.js';

export default {
    name: 'TrialPopup',
    components: { Icon },
    data() {
        return {
            timeLeft: 0,
            packages: [],
            promo: null,
            isOpened: false
        }
    },
    watch: {
        timeLeft: {
            handler(value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.timeLeft -= 1;
                    }, 1000);
                }
            }
        }
    },
    computed: {
        ...mapGetters(['clientObj', 'brandName', 'token']),
        seconds() {
            return this.timeLeft % 60;
        },
        minutes() {
            return parseInt(this.timeLeft / 60, 10) % 60;
        },
        hours() {
            return parseInt(this.timeLeft / (60 * 60), 10) % 24;
        },
        days() {
            return parseInt(this.timeLeft / (60 * 60 * 24), 10)
        }
    },
    methods: {
        async setupData() {
            try {
                const promos = await ApiService.publicApi.getTrialPromo(this.token);
                this.promo = promos.validTrialPromos[0] || null;

                if (this.promo) {
                    this.timeLeft = promos.validTrialPromos[0].timeLeft;
                    const packages = await ApiService.publicApi.getPackagesList(this.token, this.promo.name);
                    this.packages = packages.reduce((acc, p) => {
                        if (!p.promo) {
                            return acc
                        }

                        p.discount = (p.price - (p.price * (p.promo.percentage / 100))).toFixed(2)
                        p.price = p.price.toFixed(2)

                        return [...acc, p]
                    }, []);

                    this.isOpened = true;
                }
            } catch (error) {
                //
            }
        },
        async applyPromoAndGetPaymentPage(pack) {
            if (!pack) {
                VueService.showToastMessage({ message: this.$t('global.pleaseChoosePackage'), type: 'error' });
                return;
            }
            const packObj = this.packages.find((p) => p.value === pack);
            const loader = this.$loading.show();
            const { transaction, success } = await ApiService.publicApi.applyTrialPromoAndGetPaymentPage(this.token, pack, packObj?.promo?.promoCode);
            loader.hide();

            if(success === false) {
                VueService.showToastMessage({ message: this.$t('trialPopup.promoNotEligible'), type: 'error' });
                return;
            }

            if (transaction.amount === 0) {
                this.$router.push('ProccessingSubscription');
                return;
            }

            window.location = transaction.paymentUrl;
        },
        closeModal() {
            this.isOpened = false;
        }
    },
    created() {
        this.setupData();
    }
}
</script>

<style>
.backdrop {
    background-color: rgba(0, 0, 0, 0.7);
    top: 0;
}

.popup {
    background-color: #1b232d;
    border-radius: 0.5rem;
    max-width: 42rem;
    position: relative;
}

.pizzatime-text {
    color: #ec9027;
}

.apollogroup-text {
    color: #4daddf;
}

.pizzatime-bg {
    background-color: #ec9027;
}

.apollogroup-bg {
    background-color: #4daddf;
}

.offer-text {
    font-size: 0.75rem;
    letter-spacing: 0.1rem;
}

.timer-number {
    letter-spacing: 0.1rem;
}

.sub-card {
    background-color: #24303e;
    border-radius: 0.25rem;
    min-width: 9rem;
}

.original-price {
    position: relative;
    max-width: max-content;
}

.original-price:before {
    position: absolute;
    content: "";
    left: 0;
    top: 50%;
    right: 0;
    border-top: 2px solid;
    border-color: inherit;
    transform: rotate(-10deg);
}

.close-icon {
    position: absolute;
    right: 1rem;
    top: 1rem;
}
</style>