<template>
    <GuidesLayout :header="$t('faq.notSupported.notSupported')" :subtext="$t('faq.notSupported.xtreamCode')">
        <div class="not-supported-content">
            <div class="icon-container">
                <img src="./assets/not-supported-icon.svg" :alt="$t('faq.notSupported.notSupported')" :width="48">
            </div>
            <div>
                <p class="main-text">{{ $t('faq.notSupported.xtreamCode') }}</p>
                <p class="secondary-text">{{ $t('faq.notSupported.notSupportedItems') }}</p>
            </div>
            <router-link to="/RecommendedHardware" class="recommend-btn">
                    {{ $t('faq.notSupported.recommendedHardware') }}
            </router-link>
        </div>
    </GuidesLayout>
</template>

<script>

export default {
    components: {
        GuidesLayout: () => import('@/layouts/GuidesLayout.vue')
    },
};
</script>

<style scoped>
.not-supported-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;
    padding: 70px 20px;
}

.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 16px;
    background-color: #4d4bcd14;
}

.main-text {
    font-weight: 600;
    font-size: 20px;
    line-height: 1.2;
    text-align: center;
    color: #FFFFFF;
    margin-bottom: 10px;
}

.secondary-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 1.2;
    color: #FFFFFF;
    opacity: 0.4;
    text-align: center;
}

.recommend-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 42px;
    border-radius: 8px;
    background-color: #4E4BCD;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.2;
    color: #FFFFFF;
    text-decoration: none;
}

.recommend-btn:hover,
.recommend-btn:focus {
    opacity: 0.95;
}
</style>
