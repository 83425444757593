<template>
    <div></div>
</template>

<script>
export default {
    created() {
        this.$loading.show();
        const packId = this.$route.query.packId;
        const cid = this.$route.query.cid;

        if (packId && cid) {
            const hostName = window.location.hostname;
            const paymentLink = `https://public.${hostName}/api/public/getPaymentLink?packId=${packId}&cid=${cid}`
            window.location.href = paymentLink;
        } else {
            window.location.href = "/";
        }
    }
};
</script>
